<template>
  <el-scrollbar>
    <div class="container">
      <div v-if="state.loading2" style="position: fixed; top: 70px; width: 100vw; height: 100vh; z-index: 9999"
        v-loading="state.loading2" element-loading-text="加载中..." :element-loading-svg="svg"
        element-loading-custom-class="custom-loading-svg" element-loading-svg-view-box="-10, -10, 50, 50"></div>
      <div class="exportBtn"><el-button @click="reports3">导出</el-button></div>
      <div class="conCenter" id="zcfzbTable">
        <h2>资产负债表</h2>
        <el-table :data="state.zcfzb" :border="true" class="zcfzbTable" maxHeight="73vh"
          style="width: 95vw; margin: 0 auto;"
          :header-cell-style="{ 'height': '50px', 'lineHeight': '50px', 'textAlign': 'center' }">
          <el-table-column prop="params1" label="资产" width="220"></el-table-column>
          <el-table-column prop="params2" label="行次" width="80"></el-table-column>
          <el-table-column prop="params3" label="期末余额（汇总）"></el-table-column>
          <el-table-column prop="params4" label="年初余额（汇总）"></el-table-column>
          <el-table-column prop="params5" label="负债和所有者权益" width="280"></el-table-column>
          <el-table-column prop="params6" label="行次" width="80"></el-table-column>
          <el-table-column prop="params7" label="期末余额（汇总）"></el-table-column>
          <el-table-column prop="params8" label="年初余额（汇总）"></el-table-column>
        </el-table>
      </div>
    </div>
  </el-scrollbar>
</template>
<script lang="ts" setup>
import { ref, onMounted, provide, reactive, inject, computed, getCurrentInstance } from 'vue';
import { progressProps, type TabsPaneContext } from 'element-plus';
// import popupForm from "./popupForm.vue";
import { zcfzbApi, zcfzbExportoApi } from '../../api/index';
import { it } from 'element-plus/es/locale';
import { watch } from 'vue';
const instance = getCurrentInstance();

interface Props {
  activeName?: string,//当前tabs
  department?: string,//所在部门
  gameName?: string,//游戏名称
  startAndEndTime?: any,//查询时间
}
const props = withDefaults(defineProps<Props>(), {
  activeName: '',//当前tabs
  department: '',//所在部门
  gameName: '',//游戏名称
  startAndEndTime: [],//查询时间
})

const svg = `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `

const state: {
  zcfzb: any,//资产负债表数据
  loading: boolean,//loading
  loading2: boolean,//loading

} = reactive({
  zcfzb: [],//资产负债表数据
  loading: false,//loading
  loading2: false,//loading
})

const emits = defineEmits(['isLoading']);
const getTables = () => {
  setTimeout(async () => {
    state.loading2 = true;
    let jsons = {
      "startAndEndTime": props.startAndEndTime.length > 0 ? props.startAndEndTime.split(',') : [instance?.proxy?.$getCurrentMonth().startTimestamp, instance?.proxy?.$getCurrentMonth().endTimestamp],
      "gameName": props.gameName || '',
      "department": props.department || ''
    };
    await zcfzbApi(jsons)
      .then((res: any) => {
        // console.log('获取资产负债表-成功', res)//成功
        let datas = JSON.parse(JSON.stringify(res.result));
        console.log(datas, 'datas====')
        if (datas) {
          console.log('11111', datas, datas.monetaryCapital[0])
          let datas2 = [
            { params1: '流动资产：', params2: '', params3: '', params4: '', params5: '流动负债：', params6: '', params7: '', params8: '' },
            { params1: '货币资金', params2: '1', params3: datas.monetaryCapital[0] || '', params4: datas.monetaryCapital[1] || '', params5: '短期借款', params6: '31', params7: datas.shortTermBorrowing[0] || '', params8: datas.shortTermBorrowing[1] || '' },
            { params1: '短期投资', params2: '2', params3: datas.currentInvestment[0] || '', params4: datas.currentInvestment[1] || '', params5: '应付票据', params6: '32', params7: datas.notesPayable[0] || '', params8: datas.notesPayable[1] || '' },
            { params1: '应收票据', params2: '3', params3: datas.notesReceivable[0] || '', params4: datas.notesReceivable[1] || '', params5: '应付账款', params6: '33', params7: datas.accountsPayable[0] || '', params8: datas.accountsPayable[1] || '' },
            { params1: '应收账款', params2: '4', params3: datas.accountsReceivable[0] || '', params4: datas.accountsReceivable[1] || '', params5: '预收款项', params6: '34', params7: datas.accountCollectedInAdvance[0] || '', params8: datas.accountCollectedInAdvance[1] || '' },
            { params1: '预付款项', params2: '5', params3: datas.prepayment[0] || '', params4: datas.prepayment[1] || '', params5: '应付职工薪酬', params6: '35', params7: datas.employeePayPayable[0] || '', params8: datas.employeePayPayable[1] || '' },
            { params1: '应收股利', params2: '6', params3: datas.dividendsReceivable[0] || '', params4: datas.dividendsReceivable[0] || '', params5: '应交税费', params6: '36', params7: datas.taxPayable[0] || '', params8: datas.taxPayable[1] || '' },
            { params1: '应收利息', params2: '7', params3: datas.interestReceivable[0] || '', params4: datas.interestReceivable[1] || '', params5: '应付利息', params6: '37', params7: datas.accrualInterestPayable[0] || '', params8: datas.accrualInterestPayable[1] || '' },
            { params1: '其他应收款', params2: '8', params3: datas.receivableOther[0] || '', params4: datas.receivableOther[1] || '', params5: '应付利润', params6: '38', params7: datas.profitPayable[0] || '', params8: datas.profitPayable[1] || '' },
            { params1: '存货', params2: '9', params3: datas.stock[0] || '', params4: datas.stock[1] || '', params5: '其他应付款', params6: '39', params7: datas.otherPayables[0] || '', params8: datas.otherPayables[1] || '' },
            { params1: '其中：原材料', params2: '10', params3: datas.rawMaterial[0] || '', params4: datas.rawMaterial[1] || '', params5: '', params6: '', params7: '', params8: '' },
            { params1: '在产品', params2: '11', params3: datas.goodsInProcess[0] || '', params4: datas.goodsInProcess[1] || '', params5: '', params6: '', params7: '', params8: '' },
            { params1: '库存商品', params2: '12', params3: datas.merchandiseInventory[0] || '', params4: datas.merchandiseInventory[1] || '', params5: '', params6: '', params7: '', params8: '' },
            { params1: '周转材料', params2: '13', params3: datas.revolvingMaterial[0] || '', params4: datas.revolvingMaterial[1] || '', params5: '', params6: '', params7: '', params8: '' },
            { params1: '其他流动资产', params2: '14', params3: datas.otherCurrentAssets[0] || '', params4: datas.otherCurrentAssets[1] || '', params5: '其他流动负债', params6: '40', params7: datas.otherCurrentLiability[0] || '', params8: datas.otherCurrentLiability[1] || '' },
            { params1: '流动资产合计', params2: '15', params3: datas.totalCurrentAssets[0] || '', params4: datas.totalCurrentAssets[1] || '', params5: '流动负债合计', params6: '41', params7: datas.totalCurrentLiability[0] || '', params8: datas.totalCurrentLiability[1] || '' },
            { params1: '非流动资产：', params2: '', params3: '', params4: '', params5: '  非流动负债：', params6: '', params7: '', params8: '' },
            { params1: '长期债券投资', params2: '16', params3: datas.longTermBondInvestments[0] || '', params4: datas.longTermBondInvestments[1] || '', params5: '长期借款', params6: '42', params7: datas.longTermLoan[0] || '', params8: datas.longTermLoan[1] || '' },
            { params1: '长期股权投资', params2: '17', params3: datas.longTermEquityInvestment[0] || '', params4: datas.longTermEquityInvestment[1] || '', params5: '长期应付款', params6: '43', params7: datas.longTermPayable[0] || '', params8: datas.longTermPayable[1] || '' },
            { params1: '固定资产原价', params2: '18', params3: datas.originalValueOfFixedAssets[0] || '', params4: datas.originalValueOfFixedAssets[1] || '', params5: '递延收益', params6: '44', params7: datas.deferredIncome[0] || '', params8: datas.deferredIncome[1] || '' },
            { params1: '减：累计折旧', params2: '19', params3: datas.accumulatedDepreciation[0] || '', params4: datas.accumulatedDepreciation[1] || '', params5: '其他非流动负债', params6: '45', params7: datas.otherNonCurrentLiabilities[0] || '', params8: datas.otherNonCurrentLiabilities[1] || '' },
            { params1: '固定资产账面价值', params2: '20', params3: datas.bookValueOfFixedAssets[0] || '', params4: datas.bookValueOfFixedAssets[1] || '', params5: '非流动负债合计', params6: '46', params7: datas.totalNonCurrentLiabilities[0] || '', params8: datas.totalNonCurrentLiabilities[1] || '' },
            { params1: '在建工程', params2: '21', params3: datas.constructionInProgress[0] || '', params4: datas.constructionInProgress[1] || '', params5: '负债合计', params6: '47', params7: datas.totalLiability[0] || '', params8: datas.totalLiability[1] || '' },
            { params1: '工程物资', params2: '22', params3: datas.engineeringMaterial[0] || '', params4: datas.engineeringMaterial[1] || '', params5: '', params6: '', params7: '', params8: '' },
            { params1: '固定资产清理', params2: '23', params3: datas.liquidationOfFixedAssets[0] || '', params4: datas.liquidationOfFixedAssets[1] || '', params5: '', params6: '', params7: '', params8: '' },
            { params1: '生产性生物资产', params2: '24', params3: datas.productiveBiologicalAsset[0] || '', params4: datas.productiveBiologicalAsset[1] || '', params5: '所有者权益（或股东权益）：', params6: '', params7: '', params8: '' },
            { params1: '无形资产', params2: '25', params3: datas.intangibleAssets[0] || '', params4: datas.intangibleAssets[1] || '', params5: '实收资本（或股本）', params6: '48', params7: datas.paidInCapital ? datas.paidInCapital[0] : '', params8: datas.paidInCapital ? datas.paidInCapital[1] : '' },
            { params1: '开发支出', params2: '26', params3: datas.developmentExpenditure[0] || '', params4: datas.developmentExpenditure[1] || '', params5: '资本公积', params6: '49', params7: datas.capitalReserves[0] || '', params8: datas.capitalReserves[1] || '' },
            { params1: '长期待摊费用', params2: '27', params3: datas.longTermUnamortizedExpenses[0] || '', params4: datas.longTermUnamortizedExpenses[1] || '', params5: '盈余公积', params6: '50', params7: datas.earnedSurplus[0] || '', params8: datas.earnedSurplus[1] || '' },
            { params1: '其他非流动资产', params2: '28', params3: datas.otherNonCurrentAssets[0] || '', params4: datas.otherNonCurrentAssets[1] || '', params5: '未分配利润', params6: '51', params7: datas.undistributedProfit[0] || '', params8: datas.undistributedProfit[1] || '' },
            { params1: '非流动资产合计', params2: '29', params3: datas.summationOfNonCurrentAssets[0] || '', params4: datas.summationOfNonCurrentAssets[1] || '', params5: '所有者权益（或股东权益）合计', params6: '52', params7: datas.totalOwnersEquity[0] || '', params8: datas.totalOwnersEquity[1] || '' },
            { params1: '资产总计', params2: '30', params3: datas.totalAssets[0] || '', params4: datas.totalAssets[1] || '', params5: '负债和所有者权益（或股东权益）总计', params6: '53', params7: datas.liabilitiesAndEquityTotal[0] || '', params8: datas.liabilitiesAndEquityTotal[1] || '' },
          ];
          console.log(datas2, 'datas2====')
          state.zcfzb = datas2;
        }
        emits('isLoading', false)
        state.loading2 = false;
      })
      .catch((error: any) => {
        // console.log('获取资产负债表-失败', error)//失败
        state.loading2 = false;
      })
  }, 100)
}

const reports3 = async () => {
  // var tabelStr = document.querySelector('#zcfzbTable').outerHTML;
  // instance?.proxy?.$getExportFile1(tabelStr, '资产负债表');// 表格类型
  // instance?.proxy?.$getExportFile2(tabelStr, '利润表');// html类型

  state.loading = true;
  console.log('---')
  let jsons = {
    "startAndEndTime": props.startAndEndTime.length > 0 ? props.startAndEndTime.split(',') : [instance?.proxy?.$getCurrentMonth().startTimestamp, instance?.proxy?.$getCurrentMonth().endTimestamp],
    "gameName": props.gameName || '',
    "department": props.department || ''
  };
  await zcfzbExportoApi(jsons)
    .then((res: any) => {
      console.log('资产负债表---导出', res)//成功
      // 创建一个隐藏的a链接
      const link = document.createElement('a');
      // 文件流转化为对应格式的文件
      const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
      // 把a标签隐藏
      link.style.display = 'none';
      link.href = URL.createObjectURL(blob);
      // 设置文件下载后的名称
      link.download = '资产负债表.xlsx';
      document.body.appendChild(link);
      link.click();
    })
    .catch((error: any) => {
      console.log('资产负债表---导出-失败', error)//失败
    }).finally(() => {
      state.loading = false;
    })
}

onMounted(() => {
  getTables();
})

// watch(() => props.department, (newVal) => {
//   state.department = newVal;
// })
// watch(() => props.gameName, (newVal) => {
//   state.gameName = newVal;
// })
// watch(() => props.startAndEndTime, (newVal) => {
//   state.startAndEndTime = newVal;
// })


</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
}

.exportBtn {
  width: 95vw;
  margin: 0 auto 20px;
  padding: 10px 0 0;
  text-align: left;

  :deep(.el-button) {
    color: #fff;
    background-color: #0089ff;
    border-color: #0089ff;
    transform: scale(1.2);
  }
}

.conCenter {
  flex: 1;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

p {
  margin: 0;
  padding: 0;
}

h2 {
  width: 100%;
  height: 80px;
  color: #111;
  line-height: 60px;
}

.zcfzbTable {
  flex: 1;

  :deep(&.el-table) {

    th .cell {
      height: 50px;
      line-height: 50px;
      text-align: center;
    }

    tr {
      td {

        &:nth-child(2),
        &:nth-child(6) {
          text-align: center;
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(7),
        &:nth-child(8) {
          text-align: right;
        }
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9),
      &:nth-child(10),
      &:nth-child(15),
      &:nth-child(18),
      &:nth-child(19),
      &:nth-child(20),
      &:nth-child(21),
      &:nth-child(27),
      &:nth-child(28),
      &:nth-child(29),
      &:nth-child(30) {
        td {

          &:nth-child(1),
          &:nth-child(5) {
            text-indent: 32px;
          }
        }
      }

      &:nth-child(22),
      &:nth-child(23),
      &:nth-child(24),
      &:nth-child(25),
      &:nth-child(26) {
        td {

          &:nth-child(1) {
            text-indent: 32px;
          }
        }
      }

      &:nth-child(11),
      &:nth-child(12),
      &:nth-child(13),
      &:nth-child(14),
      &:nth-child(16),
      &:nth-child(31) {
        td {
          &:nth-child(1) {
            text-indent: 64px;
          }
        }
      }

      &:nth-child(16),
      &:nth-child(22) {
        td {
          &:nth-child(5) {
            text-indent: 64px;
          }
        }
      }
    }

    .cell {
      color: #111;
    }
  }
}
</style>