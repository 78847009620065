<template>
    <!-- 对话框表单 -->
    <el-dialog class="yidaDialog" v-model="state.dialogFormVisible" @close="toggledialogVisible" width="40%"
        :show-close="false">
        <template #header>{{ state.accountRows.id ? '编辑账号' : '新增账号' }}</template>
        <el-scrollbar max-height="65vh">
            <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm"
                status-icon>
                <el-form-item label="账号" prop="account">
                    <el-input v-model="ruleForm.account" placeholder="请输入" clearable v-if="!state.accountRows.id" />
                    <span v-else>
                        {{ ruleForm.account }}
                    </span>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input v-model="ruleForm.password" placeholder="请输入" clearable v-if="!state.isEdit" />
                    <div v-else class="editPass">
                        <span>{{ ruleForm.password }}</span>
                        <span @click="state.isEdit = !state.isEdit">更改</span>
                    </div>
                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-select v-model="ruleForm.state" placeholder="启用" clearable>
                        <el-option label="启用" value="0" />
                        <el-option label="禁用" value="1" />
                    </el-select>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input v-model="ruleForm.remark" type="textarea" :rows="3" resize="none" clearable />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm(ruleFormRef)">
                        确认
                    </el-button>
                    <el-button @click="resetForm(ruleFormRef)">取消</el-button>
                </el-form-item>
            </el-form>
        </el-scrollbar>
    </el-dialog>
</template>

<script setup lang="ts">
import { reactive, ref, watch, inject, onMounted, nextTick, } from 'vue'
import { ElMessage, type ComponentSize, type FormInstance, type FormRules } from 'element-plus'
import { accountAddApi, accountEditApi } from '../../api/index';

// interface RuleForm {
//     id: string
//     account: string
//     password: string
//     state: string
//     remark: string
// }

const ruleFormRef = ref<FormInstance>()
// const ruleForm = reactive<RuleForm>({
const ruleForm = reactive({
    id: '',
    account: '',
    password: '',
    state: '0',
    remark: '',
})

// const rules = reactive<FormRules<RuleForm>>({
const rules = reactive({
    account: [
        { required: true, message: '请输入账号', trigger: 'blur' },
        // { min: 3, max: 5, message: '长度在3-5之间', trigger: 'blur' },
    ],
    password: [
        { required: true, message: '请输入密码', trigger: 'blur' },
    ],
    state: [
        { required: true, message: '请选择状态', trigger: 'change' },
    ],
})

interface Props {
    dialogFormVisible?: boolean,//弹窗显隐
    accountRows?: any,//当前操作账号信息
}
const props = withDefaults(defineProps<Props>(), {
    dialogFormVisible: false,
    accountRows: {},
})

const state: {
    dialogFormVisible: boolean,//弹窗显隐
    accountRows: any,//当前操作账号信息
    isEdit: boolean,
} = reactive({
    dialogFormVisible: false,//弹窗显隐
    accountRows: {},//当前操作账号信息
    isEdit: false,
})

const emits = defineEmits(['toggleDialog']);
const toggledialogVisible = () => {
    emits('toggleDialog', false)
    state.dialogFormVisible = false;
    state.accountRows = {};



    // ruleFormRef.value.resetFields();
    // ruleFormRef.value.clearValidate();




    // nextTick(() => {
    //     ruleFormRef.value.resetFields();
    //     ruleFormRef.value.clearValidate();
    // })
}

const submitForm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return
    await formEl.validate((valid, fields) => {
        if (valid) {
            if (state.accountRows.id) {//编辑
                let jsons = {
                    id: state.accountRows.id,
                    account: state.accountRows.account,
                    password: ruleForm.password,
                    state: ruleForm.state,
                    remark: ruleForm.remark,
                    token: localStorage.getItem('token'),
                };
                accountEditApi(jsons)
                    .then((res: any) => {
                        console.log('编辑-成功', res)//成
                        if (res.code == 0) {
                            ElMessage({
                                type: 'success',
                                message: '编辑成功！',
                            })
                        }
                        toggledialogVisible();
                        formEl.resetFields();
                        formEl.clearValidate();
                    })
                    .catch((error: any) => {
                        console.log('编辑-失败', error)//失败
                    })
            } else {//新增
                let jsons = {
                    account: ruleForm.account,
                    password: ruleForm.password,
                    state: ruleForm.state,
                    remark: ruleForm.remark,
                    token: localStorage.getItem('token'),
                };
                accountAddApi(jsons)
                    .then((res: any) => {
                        console.log('新增-成功', res)//成功
                        if (res.code == 0) {
                            ElMessage({
                                type: 'success',
                                message: '新增成功！',
                            })
                        }
                        toggledialogVisible();
                        formEl.resetFields();
                        formEl.clearValidate();
                    })
                    .catch((error: any) => {
                        console.log('新增-失败', error)//失败
                    })
            }


        } else {
            console.log('error submit!', fields)
        }
    })
}

const resetForm = (formEl: FormInstance | undefined) => {
    if (!formEl) return
    formEl.resetFields();
    formEl.clearValidate();
    toggledialogVisible();
}

watch(
    () => props.dialogFormVisible,
    (newVal) => {
        if (newVal) {
            state.dialogFormVisible = props.dialogFormVisible;
            state.accountRows = props.accountRows;
            state.isEdit = state.accountRows.id ? true : false;

            if (props.accountRows.id) {
                nextTick(() => {
                    // ruleFormRef.value.resetFields();
                    // ruleFormRef.value.clearValidate();
                    ruleForm.id = state.accountRows.id;
                    ruleForm.account = state.accountRows.account;
                    ruleForm.password = state.accountRows.password;
                    ruleForm.state = state.accountRows.state;
                    ruleForm.remark = state.accountRows.remark;
                })
            }



            // nextTick(() => {
            //     ruleFormRef.value.resetFields()
            // })

            // ruleForm = state.accountRows;



        }
    }
)
</script>

<style lang="less" scoped>
:deep(.el-form) {
    width: 80%;
    margin: 20px auto;

    .el-button {
        width: 100px;
        height: 32px;

        &:nth-child(1) {
            background-color: #2e4099;
            border-color: #2e4099;
        }

        &:nth-child(2) {
            &:hover {
                color: #2e4099;
                border-color: #2e4099;
                background-color: rgba(45, 62, 149, 0.1);
            }
        }
    }

    .isReadonly {
        // background-color: red;

        // :deep(.el-input) {
        //     border: none;
        // }
    }
}

.editPass {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span:nth-child(2) {
        color: #43bef4;
        text-decoration: underline;
        cursor: pointer;
    }
}
</style>