<template>
    <!-- 对话框表单 -->
    <div id="reportDialog">
        <el-dialog class="reportDialog" v-model="state.dialogReportVisible" @close="toggleReportDialogVisible"
            width="60%">
            <template #header><span style="color: #000;">{{ state.dialogData.title }}</span></template>
            <div class="reportInfoTable">
                <el-table v-if="state.dialogData.title.indexOf('收款单') !== -1" :data="state.dialogData.data"
                    maxHeight="65vh" :border="true">
                    <el-table-column label="单据编号" prop="formData.textField_lyij7trw" minWidth="90" />
                    <el-table-column label="单据日期" prop="formData.dateField_lyicv3d3" minWidth="90">
                        <template #default="scope">{{ timestampToDate(scope.row.formData.dateField_lyicv3d3) }}</template>
                    </el-table-column>
                    <el-table-column label="单据类型" prop="formData.selectField_lys2wen8" minWidth="90" />
                    <el-table-column label="往来单位" prop="formData.textField_lys2wenc" minWidth="90" />
                    <el-table-column label="所在部门" prop="formData.departmentSelectField_lys2wenf" minWidth="90" />
                    <el-table-column label="游戏名称" prop="formData.textField_lz9njbm7" minWidth="90" />
                    <el-table-column label="游戏编号" prop="formData.textField_lz9njbm8" minWidth="90" />
                    <el-table-column label="游戏账号" prop="formData.textField_lzc5unxq" minWidth="90" />
                    <el-table-column label="金额" prop="formData.numberField_lys2wenb" minWidth="90" />
                </el-table>
                <el-table v-else-if="state.dialogData.title.indexOf('付款单') !== -1" :data="state.dialogData.data"
                    maxHeight="65vh" :border="true">
                    <el-table-column label="单据编号" prop="formData.textField_ly73ykjc" minWidth="90" />
                    <el-table-column label="单据日期" prop="formData.dateField_lyicv3d3" minWidth="90">
                        <template #default="scope">{{ timestampToDate(scope.row.formData.dateField_lyicv3d3)
                            }}</template>
                    </el-table-column>
                    <el-table-column label="单据类型" prop="formData.selectField_lyij7tru" minWidth="90" />
                    <el-table-column label="往来单位" prop="formData.textField_ly5nuqdh" minWidth="90" />
                    <el-table-column label="所在部门" prop="formData.departmentSelectField_lyij7ts7" minWidth="90" />
                    <el-table-column label="游戏名称" prop="formData.selectField_lz8a2w5s" minWidth="90" />
                    <el-table-column label="游戏编号" prop="formData.textField_lz8a2w5u" minWidth="90" />
                    <el-table-column label="游戏账号" prop="formData.textField_lzgoy567" minWidth="90" />
                    <el-table-column label="金额" prop="formData.numberField_lyij7ts4" minWidth="90" />
                </el-table>
            </div>
            <el-button @click="reportsInfo">导出</el-button>
        </el-dialog>
    </div>
</template>

<script setup lang="ts">
import { reactive, ref, watch, inject, onMounted, getCurrentInstance } from 'vue';
const instance = getCurrentInstance();

interface Props {
    dialogReportVisible?: boolean,//弹窗显隐
    dialogData?: any,//弹窗内容
}
const props = withDefaults(defineProps<Props>(), {
    dialogReportVisible: false,
    dialogData: {},//弹窗内容
})

const state: {
    dialogReportVisible: boolean,//弹窗显隐
    dialogData: any,
} = reactive({
    dialogReportVisible: false,//弹窗显隐
    dialogData: {},
})

// 转换时间戳
const timestampToDate = (timestamp: string) => {
    console.log(timestamp,'timestamp===')
    let date = new Date(timestamp);
    let year = date.getFullYear();
    let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1; // 月份从0开始，需要加1
    let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    return `${year}-${month}-${day}`;
}

const base64s = (s: any) => {
    return window.btoa(unescape(encodeURIComponent(s)))
};
const reportsInfo = () => {
    var tabelStr = document.querySelector('.reportInfoTable').outerHTML;
    instance?.proxy?.$getExportFile1(tabelStr, state.dialogData.title);// 表格类型
    // instance?.proxy?.$getExportFile2(tabelStr, state.dialogData.title);// html类型

    //     // 表格类型
    //     var tabelStr = document.querySelector('.reportInfoTable').outerHTML;
    //     var uri = 'data:application/vnd.ms-excel;base64,';
    //     // 真正要导出（下载）的HTML模板
    //     var exportTemplate = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
    //   <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
    //         <x:Name>${tabelStr}</x:Name>
    //                 <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
    //          </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
    //   </head>
    //   <body>
    //   ${tabelStr}
    //   </body>
    //   </html>`;
    //     var blob = new Blob([exportTemplate], { type: "application/vnd.ms-excel; charset=utf-8" });
    //     var link = document.createElement("a");
    //     link.href = uri + base64s(exportTemplate);
    //     link.download = state.dialogData.title + ".xls";
    //     link.innerHTML = state.dialogData.title + "xls"
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);

    // html类型
    // var tableHTML = document.querySelector("#xjllbTable").outerHTML;
    // var xlsContent = `<html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel"
    //   xmlns="http://www.w3.org/TR/REC-html40">
    // <head>
    //   <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    //   <meta name="ProgId" content="Excel.Sheet" /> 
    // </head>
    // <body>${tableHTML}</body>
    // </html>`;
    // var blob = new Blob([xlsContent], { type: "application/vnd.ms-excel; charset=utf-8" });
    // var link = document.createElement("a");
    // link.href = URL.createObjectURL(blob);
    // link.download = "现金流量表.xls";
    // link.innerHTML = "现金流量表xls"
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
}

const emits = defineEmits(['toggleReportDialog']);
const toggleReportDialogVisible = () => {
    emits('toggleReportDialog', false)
    state.dialogReportVisible = false;
}
watch(
    () => props.dialogReportVisible,
    (newVal) => {
        if (newVal) {
            state.dialogReportVisible = props.dialogReportVisible;
            state.dialogData = props.dialogData;
        }
    }
)
</script>

<style lang="less" scoped>
.reportDialog {
    :deep(&.el-dialog) {
        .el-dialog__header {
            padding-left: 0;
            font-size: 15px;
            text-align: left;
            border-bottom: 1px solid var(--ba-bg-color);
        }

        .el-dialog__body {
            padding-top: 15px;
        }

        .el-dialog__headerbtn:hover .el-dialog__close {
            color: #2e4099;
        }
    }
}

.reportInfoTable {
    :deep(.el-table) {
        .cell {
            text-align: center;
        }
    }
}
</style>
