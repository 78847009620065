<template>
  <div class="wrapper">
    <div class="header" v-if="store.state.navbar">
      <p class="titles">玩趣游游戏交易系统管理后台</p>
      <div class="users">
        <el-avatar src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" />
        <el-popconfirm width="220" :icon="WarningFilled" icon-color="#465ba6" title="确认退出登录？" @cancel="() => { }"
          @confirm="logout">
          <template #reference>
            <span>{{ store.state.account }}</span>
          </template>
          <template #actions="{ confirm, cancel }">
            <el-button size="small" @click="cancel">取消</el-button>
            <el-button type="danger" size="small" @click="confirm">
              确定
            </el-button>
          </template>
        </el-popconfirm>
      </div>
    </div>
    <div class="container">
      <nav v-if="store.state.navbar">
        <ul>
          <li v-for="route in routers" :key="route.path">
            <router-link :to="route.path" v-if="isManager() && route?.meta?.title">
              {{ route?.meta?.title }}
            </router-link>
            <router-link :to="route.path"
              v-else-if="!isManager() && route?.meta?.title && route?.meta?.title !== '账号管理'">
              {{ route?.meta?.title }}
            </router-link>
          </li>
        </ul>
        <!-- <router-link to="/">首页</router-link>
      <router-link to="/flowView">流程运行</router-link>
      <router-link to="/reportView" style="display: none;">报表展示</router-link> -->
      </nav>
      <div class="content"
        :style="{ width: store.state.navbar ? `calc(100%-220px)` : `100%`, paddingLeft: store.state.navbar ? `20px` : `0px` }">
        <router-view v-slot="{ Component }">
          <Transition name="slide-fade">
            <component :is="Component" />
          </Transition>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import store from "./store";
import { debounce } from "lodash";
import { WarningFilled } from '@element-plus/icons-vue'
// const router = useRouter();
// const routes = router.getRoutes();
import router from '@/router';
import func from 'vue-temp/vue-editor-bridge';
const routers = router.options.routes;

const debounce = (fn: any, delay: any) => {
  let timer: any;
  return (...args: any) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};
const resizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends resizeObserver {
  constructor(callback: any) {
    callback = debounce(callback, 200);
    super(callback);
  }
};

// 退出登录
const logout = () => {
  localStorage.setItem('token', '');
  localStorage.setItem('account', '');
  router.push({ path: "/loginView" });
}

const isManager = () => {
  return localStorage.getItem('account') === 'admin'
}

</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.header {
  width: 100%;
  min-height: 90px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #465ba6;
  box-sizing: border-box;

  .titles {
    margin: 0;
    padding: 0;
    font-size: 32px;
    color: #fff;
    font-weight: bold;
  }

  .users {
    flex: 1;
    min-width: 140px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    :deep(.el-avatar) {
      width: 50px;
      height: 50px;
    }

    span {
      margin-left: 10px;
      font-size: 16px;
      color: #fff;
    }
  }
}

.container {
  flex: 1;
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  // height: 100vh;
  // display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  // justify-content: space-between;
}

nav {
  width: 180px;
  align-self: stretch;
  background-color: #e8efff;
  // #e8efff
  // #0c2c6f
  // #d5defb
  // #7d9cea
  // #9ab8fb
  // #2e4099

  a {
    display: block;
    height: 50px;
    font-size: 15px;
    font-weight: bold;
    color: #2e4099;
    line-height: 50px;
    text-decoration: none;

    &.router-link-exact-active {
      color: #fff;
      background-color: #2e4099;
    }
  }
}

.content {
  flex: 1;
  // width: calc(100% - 220px);
  align-self: stretch;
  // padding-left: 20px;
  overflow: hidden;
}

.slide-fade-enter-active {
  transition: all 0.2s ease-in-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>