<template>
  <div class="content">
    <el-form ref="searchRef" :inline="true" :model="formInline" class="searchForm" label-width="80px">
      <el-form-item label="账号" prop="account">
        <el-input v-model="formInline.account" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item label="创建时间" prop="date">
        <el-date-picker v-model="formInline.date" type="daterange" range-separator="至" start-placeholder="开始时间"
          end-placeholder="结束时间" size="default" format="YYYY/MM/DD" value-format="YYYY-MM-DD" clearable />
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select v-model="formInline.status" placeholder="启用" clearable>
          <el-option label="启用" value="0" />
          <el-option label="禁用" value="1" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit(searchRef)">查询</el-button>
        <el-button type="primary" @click="onReset(searchRef)">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="btns">
      <el-button type="primary" style="background-color: #2e4099; border-color: #2e4099;"
        @click="accountDialog('add')">新增</el-button>
      <el-button type="primary" style="background-color: #2e4099; border-color: #2e4099;"
        @click="accountDialog('edit')">编辑</el-button>
      <el-button type="primary" style="background-color: #2e4099; border-color: #2e4099;"
        @click="accountDel">删除</el-button>
    </div>
    <el-table ref="multipleTable" :data="state.tableData" :border="true" class="accountTables" maxHeight="80vh"
      :header-cell-style="state.headerCellStyle" :cell-style="state.cellStyle"
      @selection-change="handleSelectionChange">
      <el-table-column fixed type="selection" width="55" />
      <el-table-column fixed type="index" label="序号" width="100" />
      <el-table-column prop="account" label="账号" width="240px" />
      <el-table-column prop="password" label="密码" width="240px" show-overflow-tooltip>
        <template #default="scope">
          <div style="height: 23px; display: inline-flex; align-items: center; justify-content: center;">
            <span>{{ state.tableCellId !== scope.row.id ? '******' : scope.row.password }}</span>
            <el-icon color="#465ba6" style="margin-left: 12px; cursor: pointer;" size="18">
              <View v-if="state.tableCellId == scope.row.id" @click="state.tableCellId = ''" />
              <Hide v-else @click="state.tableCellId = scope.row.id" />
            </el-icon>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="creationTime" label="创建时间" width="200px" />
      <el-table-column prop="modTime" label="修改时间" width="200px" />
      <el-table-column prop="state" label="状态" width="200px">
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.state === '0'">启用</el-tag>
          <el-tag type="danger" v-else-if="scope.row.state === '1'">禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" show-overflow-tooltip />
    </el-table>
    <div class="pagination">
      <el-pagination v-model:current-page="state.currentPage" v-model:page-size="state.pageSize"
        layout="prev, pager, next, jumper, ->, total" background :total="state.total" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" />
    </div>

    <!-- 表单 -->
    <popupForm :dialogFormVisible="state.dialogFormVisible" :accountRows="state.accountRows"
      @toggleDialog="closeDialog" />
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, provide, reactive, inject, computed, getCurrentInstance } from 'vue';
import { ElMessage, ElMessageBox, FormInstance } from 'element-plus';
import { View, Hide } from '@element-plus/icons-vue'
import popupForm from './popupForm.vue';
import { accountDelApi, accountPageApi } from '../../api/index';

interface RuleForm {
  account: string,
  date: string,
  status: string,
}
const searchRef = ref<FormInstance>()
const formInline = reactive<RuleForm>({
  account: '',
  date: '',
  status: '',
})

const state: {
  tableData: any,
  headerCellStyle: any,
  cellStyle: any,
  tableCellId: string,
  currentPage: number,//当前页码
  pageSize: number,//每页条数
  total: number,//总条数
  dialogFormVisible: boolean,//弹窗
  multipleSelection: any,//选择的行
  accountRows: any,//当前操作的数据
} = reactive({
  tableData: [],
  headerCellStyle: {
    color: '#333',
    textAlign: 'center',
    borderColor: '#797979',
    backgroundColor: '#d7d7d7',
  },
  cellStyle: {
    textAlign: 'center',
    borderColor: '#797979',
  },
  tableCellId: '',
  currentPage: 1,
  pageSize: 10,
  total: 100,
  dialogFormVisible: false,//弹窗
  multipleSelection: [],//选择的行
  accountRows: {},//当前操作的数据
})

const multipleTable = ref();

const onSubmit = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!', formInline)
      getPage();
    } else {
      console.log('error submit!', fields)
    }
  })
}

const onReset = (formEl: FormInstance | undefined) => {
  console.log(formInline, '222222tate.formInline===')
  if (!formEl) return
  formEl.resetFields()
  console.log(formInline, 'state.formInline===')
  getPage();
}

const handleSizeChange = (val: number) => {
  state.pageSize = val;
  getPage();
}
const handleCurrentChange = (val: number) => {
  state.currentPage = val;
  getPage();
}

const handleSelectionChange = (val: any) => {
  state.multipleSelection = val;
}

const accountDialog = (type: string) => {
  if (type === 'add') {
    state.accountRows = {};
    state.multipleSelection = [];//刷新列表
    multipleTable.value.clearSelection();
  } else if (type === 'edit') {
    var mulNums = state.multipleSelection.length;
    if (mulNums !== 1) {
      ElMessage({
        type: 'error',
        message: mulNums === 0 ? '请选择需要编辑的行！' : '请仅选择一条数据进行编辑！',
      })
      return
    }
    state.accountRows = state.multipleSelection[0];
  }
  state.dialogFormVisible = true;
}

const accountDel = () => {
  console.log(state.multipleSelection, !state.multipleSelection.length, '哈哈哈哈')
  if (!state.multipleSelection.length) {
    ElMessage({
      type: 'error',
      message: '请选择需要删除的行！',
    })
    return
  }
  console.log('ElMessage====')
  ElMessageBox.confirm(
    '确认删除',
    '删除账号',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: '',
      closeOnPressEscape: false,
      closeOnClickModal: false,
      showClose: false,
      customClass: 'accountElMessageBox'
    }
  )
    .then(async () => {
      let ids = state.multipleSelection.filter((item: any) => {
        return item.id
      }).map((obj: any) => obj.id)
      console.log(state.multipleSelection, ids, ';ids')
      let jsons = {
        ids: ids,
        token: localStorage.getItem('token'),
      };
      accountDelApi(jsons)
        .then((res: any) => {
          console.log('删除-成功', res)//成功
          state.tableData = res.result?.data;
          state.total = res.result?.count;
          ElMessage({
            type: 'success',
            message: '删除成功！',
          })
          state.multipleSelection = [];//刷新列表
          multipleTable.value.clearSelection();
          getPage();
        })
        .catch((error: any) => {
          console.log('删除-失败', error)//失败
        })
    })
    .catch(() => {
      state.multipleSelection = [];//刷新列表
      multipleTable.value.clearSelection();
      getPage();
    })
}

// 关闭弹窗
const closeDialog = () => {
  state.accountRows = {};
  state.dialogFormVisible = false;
  state.multipleSelection = [];//刷新列表
  multipleTable.value.clearSelection();
  getPage();
}

// 列表
const getPage = async () => {
  let jsons = {
    page: state.currentPage,
    pageSize: state.pageSize,
    startTime: formInline.date ? formInline.date[0] : '',
    endTime: formInline.date ? formInline.date[1] : '',
    state: formInline.status,
    account: formInline.account ? formInline.account : '',
    token: localStorage.getItem('token'),
  };
  accountPageApi(jsons)
    .then((res: any) => {
      console.log('列表-成功', res)//成功
      state.tableData = res.result?.data;
      state.total = res.result?.count;
    })
    .catch((error: any) => {
      console.log('列表-失败', error)//失败
    })
}

onMounted(() => {
  getPage();
})

</script>

<style lang="less" scoped>
.content {
  // width: 100%;
  // height: 100%;
  // padding-bottom: 10%;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // box-sizing: border-box;
  /* border: 1px solid teal; */
}

.searchForm {
  width: 100%;

  :deep(&.el-form) {
    margin-top: 20px;
    display: inline-flex;
    align-items: flex-start;
    flex-flow: row wrap;

    .el-form-item {
      color: #111;

      .el-form-item__label {
        justify-content: flex-start;
      }

      .el-input {
        --el-input-width: 290px;
      }

      .el-select {
        --el-select-width: 290px;
      }

      .el-date-editor {
        width: 270px !important;
      }

      .el-button {
        width: 100px;
        height: 36px;

        &:nth-child(1) {
          margin-left: 80px;
          background-color: #2e4099;
          border-color: #2e4099;
        }

        &:nth-child(2) {
          color: #7f7f7f;
          background-color: #fff;
          border-color: #7f7f7f;
        }

      }
    }
  }
}

.btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: row wrap;

  :deep(.el-button) {
    width: 80px;
    height: 28px;
  }
}

.accountTables {
  :deep(&.el-table) {
    width: calc(100% - 20px);
    margin: 10px 0;


    .el-table__inner-wrapper:before,
    .el-table__inner-wrapper:after,
    .el-table__border-left-patch,
    &.el-table--border:after {
      background-color: #797979;
    }

    .el-checkbox__inner {
      border-color: #797979;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #2e4099;
      border-color: #2e4099;
    }
  }
}
</style>
<style lang="less">
.accountElMessageBox {

  .el-message-box__message {
    margin: 40px auto;
    font-size: 16px;
  }

  .el-message-box__btns {
    flex-direction: row-reverse;
    justify-content: center;

    .el-button {
      width: 80px;
      height: 30px;
      margin: 0 0 20px 12px;

      &:nth-child(1) {
        border-color: #2e4099;

        &:hover {
          color: #2e4099;
          background-color: rgba(45, 62, 149, 0.1);
        }
      }

      &:nth-child(2) {
        background-color: #2e4099;
        border-color: #2e4099;
      }
    }
  }
}
</style>