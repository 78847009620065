<template>
  <div class="home">
    <img class="logo" src="../../assets/imgs/logo.png" mode="widthFix" />
    <p>欢迎使用玩趣游游戏交易系统管理后台</p>
    <p>请妥善保管您的账号及密码</p>
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  height: 100%;
  padding-bottom: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  .logo {
    width: 15%;
    height: auto;
    margin-bottom: 15px;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 34px;
    font-weight: bold;
    color: #2e4099;
    text-align: center;
    line-height: 1.6;
  }
}
</style>
