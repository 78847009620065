<template>
  <div class="home">
    <div class="loginCon">
      <div class="loginLeft">
        <p>玩趣游游戏交易系统管理后台</p>
      </div>
      <div class="loginRight">
        <img class="logo" src="../../assets/imgs/logo.png" />

        <div class="formCon">
          <el-form @keyup.enter="submitForm(ruleFormRef)" ref="ruleFormRef" style="max-width: 600px" :model="ruleForm"
            :rules="rules" label-width="auto" status-icon>
            <div class="welcome">欢迎登录</div>
            <el-form-item label="" prop="account">
              <el-input :prefix-icon="Avatar" v-model="ruleForm.account" placeholder="请输入用户账号" />
            </el-form-item>
            <el-form-item label="" prop="password">
              <el-input :prefix-icon="Unlock" v-model="ruleForm.password" type="password" autocomplete="off"
                placeholder="请输入密码" show-password />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm(ruleFormRef)">
                登录
              </el-button>
            </el-form-item>
            <div class="checkbox">
              <el-checkbox class="custom-checkbox" v-model="checked1" label="记住密码" size="large" />
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, provide, reactive, inject, computed, getCurrentInstance } from 'vue';
import { ElMessage, type ComponentSize, type FormInstance, type FormRules } from 'element-plus'
import { Avatar, Unlock } from '@element-plus/icons-vue'
import router from '@/router';
import { loginApi } from '../../api/index';

interface RuleForm {
  account: string
  password: string
}
const ruleFormRef = ref<FormInstance>()
const ruleForm = reactive<RuleForm>({
  account: '',
  password: '',
})

const rules = reactive<FormRules<RuleForm>>({
  account: [
    { required: true, message: '请输入用户账号', trigger: 'blur' },
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' },
    // { required: true, message: '请输入密码', trigger: 'blur' },
    // { min: 6, max: 12, message: '密码长度在 6 到 12 个字符', trigger: 'blur' }
  ],
})

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      let jsons = {
        account: ruleForm.account,
        password: ruleForm.password
      };
      loginApi(jsons)
        .then((res: any) => {
          console.log('登录-成功', res)//成功
          if (res.code == 0) {
            ElMessage.success(res.desc);
            setTimeout(() => {
              localStorage.setItem('token', res.result.token);
              localStorage.setItem('account', res.result.account);
              router.replace({ path: '/' })
            }, 500);
          }
        })
        .catch((error: any) => {
          console.log('登录-失败', error)//失败
        })
    } else {
      ElMessage.error('请输入正确的账号密码！');
      return false
    }
  })
}

const checked1 = ref(false);

</script>

<style lang="less" scoped>
.loginCon {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  background-color: #f3f7fa;
}

.loginLeft {
  width: 57%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5b83e0;
  z-index: 1;
  clip-path: polygon(0% 0%, 100% 0%, 80% 100%, 0% 100%); //设置多边形的属性

  p {
    max-width: 540px;
    margin: 0;
    padding: 0;
    font-size: 60px;
    color: #fff;
    font-weight: bold;
    text-align: left;
    white-space: pre-wrap;
    word-break: break-all;
  }
}

.loginRight {
  width: 43%;
  height: 100%;
  position: relative;

  .logo {
    width: 100%;
    position: absolute;
    top: 40px;
    left: 0;
    opacity: 0.3;
  }

  .formCon {
    width: 100%;
    height: 100%;
    // padding-right: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    .welcome {
      padding: 40px 0 20px;
      font-size: 30px;
      color: #5b83e0;
      text-align: left;
    }

    :deep(.el-form) {
      .el-input__wrapper {
        width: 360px;
        height: 40px;
        line-height: 40px;
        background-color: #e7eaef;
        border-color: #e7eaef;
      }

      .el-button {
        width: 100%;
        height: 40px;
        background-color: #5881ff;
        border-color: #5881ff;
      }

      .el-input .el-input__icon {
        width: 22px;
      }

      .el-icon svg {
        height: 22px;
        width: 22px;
        font-size: 18px;
        color: #4b61b2;
      }
    }

    .checkbox {
      text-align: left;


      :deep(.el-checkbox) {

        .el-input__inner {
          color: red;
        }

        .el-checkbox__label {
          color: #4b61b2;
        }

        .el-checkbox__inner {
          border-color: #4b61b2;
        }

        &:hover {
          .el-checkbox__inner {
            border-color: #4b61b2;
          }
        }

        &.is-checked {
          .el-checkbox__inner {
            background-color: #4b61b2;
            border-color: #4b61b2;
          }

          .el-checkbox__label {
            color: #4b61b2;
          }
        }
      }
    }
  }
}
</style>
