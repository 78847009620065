<template>
  <el-scrollbar max-height="90vh">
    <!-- 后台表格筛选条件：流程名称、单据编号、单据日期、往来单位文本、运行状态、实例ID -->
    <!-- 搜索部分 -->
    <el-form ref="formRef" :inline="true" :model="formInline" class="demo-form-inline" label-width="100px">
      <el-form-item label="流程名称" prop="flowMenu">
        <el-select v-model="formInline.flowMenu" placeholder="请选择" clearable>
          <el-option label="账号出售" value="账号出售" />
          <el-option label="运营订单" value="运营订单" />
          <el-option label="收号放款" value="收号放款" />
          <el-option label="收款单" value="收款单" />
          <el-option label="付款单" value="付款单" />
          <el-option label="其他收入" value="其他收入" />
          <el-option label="费用支出" value="费用支出" />
          <el-option label="预收冲应收" value="预收冲应收" />
          <el-option label="预付冲应付" value="预付冲应付" />
        </el-select>
      </el-form-item>
      <el-form-item label="单据编号" prop="billsNumber">
        <el-input v-model="formInline.billsNumber" placeholder="请输入..." clearable />
      </el-form-item>
      <el-form-item label="单据日期" prop="billsTime">
        <el-date-picker v-model="formInline.billsTime" type="daterange" range-separator="至" start-placeholder="开始日期"
          end-placeholder="结束日期" size="default" format="YYYY/MM/DD" value-format="YYYY-MM-DD" clearable />
      </el-form-item>
      <el-form-item label="往来单位" prop="clientTxt">
        <el-input v-model="formInline.clientTxt" placeholder="请输入..." clearable />
      </el-form-item>
      <el-form-item label="实例ID" prop="instanceId">
        <el-input v-model="formInline.instanceId" placeholder="请输入..." clearable />
      </el-form-item>
      <el-form-item label="运行状态" prop="instanceState">
        <el-select v-model="formInline.instanceState" placeholder="请选择" clearable>
          <el-option label="成功" value="成功" />
          <el-option label="失败" value="失败" />
          <el-option label="执行中" value="执行中" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onReset(formRef)"
          style="background-color: #2e4099; border-color: #2e4099;">重置</el-button>
        <el-button type="primary" @click="onSubmit"
          style="background-color: #2e4099; border-color: #2e4099;">查询</el-button>
      </el-form-item>
    </el-form>
    <el-tabs v-model="state.activeName" class="flowTabs" @tab-click="handleClick">
      <el-tab-pane label="全部" name=""></el-tab-pane>
      <el-tab-pane label="账号出售" name="账号出售"></el-tab-pane>
      <el-tab-pane label="运营订单" name="运营订单"></el-tab-pane>
      <el-tab-pane label="收号放款" name="收号放款"></el-tab-pane>
      <!-- <el-tab-pane label="账号问题" name="账号问题"></el-tab-pane> -->
      <el-tab-pane label="收款单" name="收款单"></el-tab-pane>
      <el-tab-pane label="付款单" name="付款单"></el-tab-pane>
      <el-tab-pane label="其他收入" name="其他收入"></el-tab-pane>
      <el-tab-pane label="费用支出" name="费用支出"></el-tab-pane>
      <el-tab-pane label="预收冲应收" name="预收冲应收"></el-tab-pane>
      <el-tab-pane label="预付冲应付" name="预付冲应付"></el-tab-pane>
    </el-tabs>
    <el-table :data="state.tableData" :border="true" class="flowTable" maxHeight="80vh">
      <el-table-column fixed prop="flowMenu" label="流程类型" minWidth="100px">
        <template #default="scope">
          <span
            @click="state.flowMenu = scope.row.flowMenu; state.formUuid = scope.row.formId; state.instanceId = scope.row.instanceId; state.dialogFormVisible = true;">{{
              scope.row.flowMenu }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="instanceId" label="流程实例" show-overflow-tooltip minWidth="100px" />
      <el-table-column prop="billsNumber" label="单据编号" width="240px" />
      <el-table-column prop="billsTime" label="单据日期" width="140px" />
      <el-table-column prop="clientTxt" label="往来单位" width="200px" />
      <el-table-column prop="sumAmount" label="金额" width="100px" />
      <el-table-column prop="creationTime" label="创建时间" width="165px" />
      <el-table-column prop="modTime" label="修改时间" width="165px" />
      <el-table-column prop="instanceState" label="状态" minWidth="80px">
        <template #default="scope">
          <el-tag
            :type="scope.row.instanceState == '成功' ? `success` : scope.row.instanceState == '失败' ? `danger` : `warning`">{{
              scope.row.instanceState }}</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination v-model:current-page="state.currentPage" v-model:page-size="state.pageSize"
        layout="prev, pager, next, jumper, ->, total" background :total="state.total" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" />
    </div>
  </el-scrollbar>

  <!-- 表单 -->
  <popupForm :dialogFormVisible="state.dialogFormVisible" :flowMenu="state.flowMenu" :formUuid="state.formUuid"
    :instanceId="state.instanceId" @toggleDialog="closeDialog" />

</template>
<script lang="ts" setup>
import { ref, onMounted, provide, reactive, inject, computed, getCurrentInstance } from 'vue';
import { FormInstance, formProps, type TabsPaneContext } from 'element-plus';
import popupForm from "./popupForm.vue";
import { addFlow, delFlow, flowList } from '../../api/index';
const instance = getCurrentInstance();

const formRef = ref<FormInstance>()

const formInline = reactive({
  flowMenu: '',//流程名称
  billsNumber: '',//单据编号
  billsTime: '',//单据日期
  clientTxt: '',//往来单位
  instanceId: '',//实例ID
  instanceState: '',//运行状态
})

const onReset = (formEl: FormInstance | undefined) => {
  if (!formEl) return
  formEl.resetFields()
  state.activeName = '';
  listDatas();
}

const onSubmit = () => {
  state.activeName = formInline.flowMenu ? formInline.flowMenu : '';
  listDatas();
}

const state: {
  activeName: string,//选项卡current
  tableData: any,//表格数据
  dialogFormVisible: boolean,//弹窗显隐
  flowMenu: string,//流程名称
  formUuid: string,//表单ID
  instanceId: string,//实例ID
  currentPage: number,//当前页码
  pageSize: number,//每页条数
  total: number,//总条数
} = reactive({
  activeName: "",
  tableData: [],
  dialogFormVisible: false,
  flowMenu: "",
  formUuid: "",
  instanceId: "",
  currentPage: 1,
  pageSize: 10,
  total: 100,
})

onMounted(() => {
  listDatas();
})

// 获取列表
const listDatas = async () => {
  var billsStartTime = '';
  var billsEndTime = '';
  if (formInline.billsTime) {
    billsStartTime = formInline.billsTime[0];
    billsEndTime = formInline.billsTime[1];
  } else {
    // billsStartTime = '1970-01-01';
    // billsEndTime = instance?.proxy?.$getCurrentDate().year + '-' + instance?.proxy?.$getCurrentDate().month + '-' + instance?.proxy?.$getCurrentDate().day;
  }
  let jsons = {
    flowMenu: state.activeName === '全部' ? '' : state.activeName,//流程名称
    page: state.currentPage,
    pageSize: state.pageSize,
    billsNumber: formInline.billsNumber,//单据编号
    billsStartTime: billsStartTime,//单据日期-开始
    billsEndTime: billsEndTime,//单据日期-结束
    clientTxt: formInline.clientTxt,//往来单位
    instanceId: formInline.instanceId,//实例ID
    instanceState: formInline.instanceState,//运行状态
  };
  await flowList(jsons)
    .then((res: any) => {
      // console.log('获取列表-成功', res)//成功
      state.total = res.result.count || 0;
      state.tableData = res.result.data;
    })
    .catch((error: any) => {
      // console.log('获取列表-失败', error)//失败
    })
};
// const { proxy } = getCurrentInstance()

// 选项卡切换
const handleClick = (tab: TabsPaneContext, event: Event) => {
  state.activeName = tab.props.label === '全部' ? '' : tab.props.label;
  listDatas();
}

const handleSizeChange = (val: number) => {
  state.pageSize = val;
  listDatas();
}
const handleCurrentChange = (val: number) => {
  state.currentPage = val;
  listDatas();
}

// 关闭弹窗
const closeDialog = () => {
  state.flowMenu = '';
  state.formUuid = '';
  state.instanceId = '';
  state.dialogFormVisible = false;
}
</script>

<style lang="less" scoped>
.flowTabs {
  margin-top: 10px;

  .el-tabs__content {
    padding: 32px;
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
  }

  :deep(.el-tabs__item.is-active),
  :deep(.el-tabs__item:hover) {
    color: #2e4099;
  }

  :deep(.el-tabs__active-bar) {
    background-color: #2e4099;
  }
}

.flowTable {
  width: 100%;
  max-width: 100%;

  :deep(th.el-table__cell),
  :deep(.el-table__header .el-table-fixed-column--left) {
    color: #fff;
    height: 46px;
    line-height: 46px;
    background-color: #2e4099;
  }

  :deep(.cell) {
    text-align: center;
  }

  :deep(.el-table__row .el-table__cell:nth-child(1)) {
    color: #2e4099;
    cursor: pointer;
  }
}

.demo-form-inline {
  width: 100%;
  padding: 20px 0 0;
  overflow: hidden;

  :deep(.el-form-item) {
    float: left;

    .el-form-item__label {
      justify-content: flex-start;
    }

    .el-input {
      --el-input-width: 240px;
    }

    .el-select {
      --el-select-width: 240px;
    }

    .el-date-editor {
      width: 220px;
    }
  }
}
</style>
