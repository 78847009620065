import { AxiosRequestConfig } from "axios";
import { request } from "../utils/request";

// export const articleCate = (params: any) => {
//     return request.get('', { params })
// }

// 新增流程实例
export const addFlow = (data: any) => {
  return request.post("/api/webservice/addProcessManagement", data);
};

// 流程实例列表
export const flowList = (data: any) => {
  return request.post("/api/webservice/getProcessManagementPage", data);
};

// 流程实例详情
export const flowInfo = (params: any) => {
  return request.get("/api/webservice/getInstanceByInstanceIdAndFormId", {
    params,
  });
};

// 删除流程实例
export const delFlow = (data: any) => {
  return request.post("/api/webservice/addProcessManagementByCancel", data);
};

// 现金流量表
export const xjllbApi = (data: any) => {
  return request.post("/api/webservice/cashFlowStatement", data);
};

// 现金流量表-详情
export const xjllbInfoApi = (data: any) => {
  return request.post("/api/webservice/getCashFlowStatementDetail", data);
};

// 现金流量表-后台导出
export const xjllbExportoApi = (data: any) => {
  return request({
    url: "/api/webservice/daochu",
    method: "post",
    data: data,
    responseType: "blob",
  });
};

// 利润表
export const lrbApi = (data: any) => {
  return request.post("/api/webservice/getProfitStatement", data);
};

// 利润表-后台导出
export const lrbExportoApi = (data: any) => {
  return request({
    url: "/api/webservice/daochuProfit",
    method: "post",
    data: data,
    responseType: "blob",
  });
};

// 资产负债表
export const zcfzbApi = (data: any) => {
  return request.post("/api/webservice/getBalanceSheet", data);
};

// 资产负债表-后台导出
export const zcfzbExportoApi = (data: any) => {
  return request({
    url: "/api/webservice/daochuBalanceSheet",
    method: "post",
    data: data,
    responseType: "blob",
  });
};

// 登录
export const loginApi = (data: any) => {
  return request.post("/api/webservice/user/logIn", data);
};

// 账号管理-列表
export const accountPageApi = (data: any) => {
  return request.post("/api/webservice/user/getUserPage", data);
};

// 账号管理-新增
export const accountAddApi = (data: any) => {
  return request.post("/api/webservice/user/addUser", data);
};

// 账号管理-编辑
export const accountEditApi = (data: any) => {
  return request.post("/api/webservice/user/modifyUser", data);
};

// 账号管理-删除
export const accountDelApi = (data: any) => {
  return request.post("/api/webservice/user/deleteUser", data);
};











// export const XXXXXXXXX = (data: any) => {
//     return request({ url: ', method: 'post', params: data })
// }
