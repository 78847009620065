<template>
  <el-scrollbar>
    <div class="container">
      <div v-if="state.loading2" style="position: fixed; top: 70px; width: 100vw; height: 100vh; z-index: 9999"
        v-loading="state.loading2" element-loading-text="加载中..." :element-loading-svg="svg"
        element-loading-custom-class="custom-loading-svg" element-loading-svg-view-box="-10, -10, 50, 50"></div>
      <div class="exportBtn"><el-button @click="reports2">导出</el-button></div>
      <div class="conCenter" :style="{ width: `95vw` }" id="lrbTable">
        <h2>利润表</h2>
        <el-table class="lrbTable" :data="state.tableDatas2" :span-method="objectSpanMethod"
          style="text-align: center;">
          <el-table-column label="行次" prop="indexs" minWidth="90" />
          <el-table-column label="项目组">
            <el-table-column label="游戏名" prop="firstHeading" minWidth="220" />
          </el-table-column>
          <el-table-column label="">
            <el-table-column label="" prop="secondHeading" minWidth="100" />
          </el-table-column>
          <el-table-column label="汇总金额" prop="summarize" minWidth="130" />
          <el-table-column v-for="(item, index) in state.deptList" :key="index" :label="item.label" minWidth="100">
            <el-table-column v-for="(item2, index2) in item.child" :key="index2" :label="item2.label" minWidth="100">
              <template #default="scope">
                {{ isZero(scope.row.numberList[item2.indexs - 1].cells) }}
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </el-scrollbar>
</template>
<script lang="ts" setup>
import { ref, onMounted, provide, reactive, inject, computed, getCurrentInstance } from 'vue';
import { progressProps, type TabsPaneContext } from 'element-plus';
import { lrbApi, lrbExportoApi } from '../../api/index';
import { it, tr } from 'element-plus/es/locale';
import { watch } from 'vue';
const instance = getCurrentInstance();

interface Props {
  activeName?: string,//当前tabs
  department?: string,//所在部门
  gameName?: string,//游戏名称
  startAndEndTime?: any,//查询时间
}
const props = withDefaults(defineProps<Props>(), {
  activeName: '',//当前tabs
  department: '',//所在部门
  gameName: '',//游戏名称
  startAndEndTime: [],//查询时间
})

const svg = `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `

const state: {
  deptList: any,//部门列表
  gameLen: number,//游戏数量
  tableDatas2: any,//表格数据
  loading: boolean,//loading
  loading2: boolean,//loading
} = reactive({
  deptList: [],//部门列表
  gameLen: 0,//游戏数量
  tableDatas2: [],//表格数据
  loading: false,//loading
  loading2: false,//loading
})

const emits = defineEmits(['isLoading']);

const isZero = (val: any) => {
  return (val === '0.00' || val === '0') ? '' : val
}

const getTables = () => {
  setTimeout(async () => {
    state.loading2 = true;
    let jsons = {
      "startAndEndTime": props.startAndEndTime.length > 0 ? props.startAndEndTime.split(',') : [instance?.proxy?.$getCurrentMonth().startTimestamp, instance?.proxy?.$getCurrentMonth().endTimestamp],
      "gameName": props.gameName || '',
      "department": props.department || ''
    };
    await lrbApi(jsons)
      .then((res: any) => {
        // console.log('获取利润表-成功', res)//成功
        let datas = JSON.parse(JSON.stringify(res.result));
        if (datas && datas.length) {
          let arrs = JSON.parse(JSON.stringify(datas));
          var taking = [];
          var zhcsBillOfSales = [];
          var zhcsGoodsReturnedNote = [];
          var qtsrBillOfSales = [];
          var qtsrGoodsReturnedNote = [];
          var operatingCosts = [];
          var zhHsAndBpBillOfSales = [];
          var zhHsAndBpGoodsReturnedNote = [];
          var otherExpenses = [];
          var shzfBillOfSales = [];
          var shzfGoodsReturnedNote = [];
          var czhfBillOfSales = [];
          var czhfGoodsReturnedNote = [];
          var yyyjBillOfSales = [];
          var yyyjGoodsReturnedNote = [];
          var qtzfBillOfSales = [];
          var qtzfGoodsReturnedNote = [];
          var payroll = [];
          var rent = [];
          var projectTeamProfit = [];
          const temp: any = {};
          var indexs = 0;
          for (let i in arrs) {
            if (arrs[i].department && arrs[i].department !== '汇总金额') {
              taking.push({ cells: arrs[i].taking })
              zhcsBillOfSales.push({ cells: arrs[i].zhcsBillOfSales })
              zhcsGoodsReturnedNote.push({ cells: arrs[i].zhcsGoodsReturnedNote })
              qtsrBillOfSales.push({ cells: arrs[i].qtsrBillOfSales })
              qtsrGoodsReturnedNote.push({ cells: arrs[i].qtsrGoodsReturnedNote })
              operatingCosts.push({ cells: arrs[i].operatingCosts })
              zhHsAndBpBillOfSales.push({ cells: arrs[i].zhHsAndBpBillOfSales })
              zhHsAndBpGoodsReturnedNote.push({ cells: arrs[i].zhHsAndBpGoodsReturnedNote })
              otherExpenses.push({ cells: arrs[i].otherExpenses })
              shzfBillOfSales.push({ cells: arrs[i].shzfBillOfSales })
              shzfGoodsReturnedNote.push({ cells: arrs[i].shzfGoodsReturnedNote })
              czhfBillOfSales.push({ cells: arrs[i].czhfBillOfSales })
              czhfGoodsReturnedNote.push({ cells: arrs[i].czhfGoodsReturnedNote })
              yyyjBillOfSales.push({ cells: arrs[i].yyyjBillOfSales })
              yyyjGoodsReturnedNote.push({ cells: arrs[i].yyyjGoodsReturnedNote })
              qtzfBillOfSales.push({ cells: arrs[i].qtzfBillOfSales })
              qtzfGoodsReturnedNote.push({ cells: arrs[i].qtzfGoodsReturnedNote })
              payroll.push({ cells: arrs[i].payroll })
              rent.push({ cells: arrs[i].rent })
              projectTeamProfit.push({ cells: arrs[i].projectTeamProfit })
              let key = arrs[i].department; // 判断依据，利用对象的属性不能重复进行判断。
              if (key) {
                if (temp[key]) {
                  indexs++;
                  temp[key].label = arrs[i].department;
                  temp[key].child.push({ indexs: indexs, label: arrs[i].gameName });// 游戏名称

                } else {
                  temp[key] = {};
                  indexs++;
                  temp[key].label = arrs[i].department;
                  temp[key].child = [];
                  temp[key].child.push({ indexs: indexs, label: arrs[i].gameName ? arrs[i].gameName : '' });// 游戏名称
                }
              }
            }
          }
          const newArry = [];
          for (let k in temp) {
            newArry.push(temp[k]);
          };
          state.deptList = newArry;//所有的部门+游戏，做表头
          state.gameLen = indexs;
          var examList = [];
          for (var i = 0; i < indexs; i++) {
            examList.push({ cells: 0 })
          }
          var tableDatas2: any = [];
          tableDatas2 = [
            {
              indexs: 1,
              firstHeading: '（一）营业收入',
              secondHeading: '',
              summarize: arrs[0].taking,
              numberList: taking,
            },
            {
              indexs: 2,
              firstHeading: '账号出售(出售价)',
              secondHeading: '销货单',
              summarize: arrs[0].zhcsBillOfSales,
              numberList: zhcsBillOfSales,
            },
            {
              indexs: 3,
              firstHeading: '账号出售(出售价)',
              secondHeading: '退货单',
              summarize: arrs[0].zhcsGoodsReturnedNote,
              numberList: zhcsGoodsReturnedNote,
            },
            {
              indexs: 4,
              firstHeading: '其他业务收入',
              secondHeading: '销货单',
              summarize: arrs[0].qtsrBillOfSales,
              numberList: qtsrBillOfSales,
            },
            {
              indexs: 5,
              firstHeading: '其他业务收入',
              secondHeading: '退货单',
              summarize: arrs[0].qtsrGoodsReturnedNote,
              numberList: qtsrGoodsReturnedNote,
            },

            {
              indexs: 1,
              firstHeading: '（二）营业成本',
              secondHeading: '',
              summarize: arrs[0].operatingCosts,
              numberList: operatingCosts,
            },
            {
              indexs: 2,
              firstHeading: '账号出售(回收价+包赔费)',
              secondHeading: '销货单',
              summarize: arrs[0].zhHsAndBpBillOfSales,
              numberList: zhHsAndBpBillOfSales,
            },
            {
              indexs: 3,
              firstHeading: '账号出售(回收价+包赔费)',
              secondHeading: '退货单',
              summarize: arrs[0].zhHsAndBpGoodsReturnedNote,
              numberList: zhHsAndBpGoodsReturnedNote,
            },

            {
              indexs: 1,
              firstHeading: '（三）其他费用',
              secondHeading: '',
              summarize: arrs[0].otherExpenses,
              numberList: otherExpenses,
            },
            {
              indexs: 2,
              firstHeading: '费用支出-售后支出',
              secondHeading: '销货单',
              summarize: arrs[0].shzfBillOfSales,
              numberList: shzfBillOfSales,
            },
            {
              indexs: 3,
              firstHeading: '费用支出-售后支出',
              secondHeading: '退货单',
              summarize: arrs[0].shzfGoodsReturnedNote,
              numberList: shzfGoodsReturnedNote,
            },
            {
              indexs: 4,
              firstHeading: '费用支出-充值话费',
              secondHeading: '销货单',
              summarize: arrs[0].czhfBillOfSales,
              numberList: czhfBillOfSales,
            },
            {
              indexs: 5,
              firstHeading: '费用支出-充值话费',
              secondHeading: '退货单',
              summarize: arrs[0].czhfGoodsReturnedNote,
              numberList: czhfGoodsReturnedNote,
            },
            {
              indexs: 6,
              firstHeading: '费用支出-运营佣金',
              secondHeading: '销货单',
              summarize: arrs[0].yyyjBillOfSales,
              numberList: yyyjBillOfSales,
            },
            {
              indexs: 7,
              firstHeading: '费用支出-运营佣金',
              secondHeading: '退货单',
              summarize: arrs[0].yyyjGoodsReturnedNote,
              numberList: yyyjGoodsReturnedNote,
            },
            {
              indexs: 8,
              firstHeading: '费用支出-其他支出',
              secondHeading: '销货单',
              summarize: arrs[0].qtzfBillOfSales,
              numberList: qtzfBillOfSales,
            },
            {
              indexs: 9,
              firstHeading: '费用支出-其他支出',
              secondHeading: '退货单',
              summarize: arrs[0].qtzfGoodsReturnedNote,
              numberList: qtzfGoodsReturnedNote,
            },
            {
              indexs: 10,
              firstHeading: '职工薪酬',
              secondHeading: '',
              summarize: arrs[0].payroll,
              numberList: payroll,
            },
            {
              indexs: 11,
              firstHeading: '预付款项-房租',
              secondHeading: '',
              summarize: arrs[0].rent,
              numberList: rent,
            },
            {
              indexs: 1,
              firstHeading: '项目组利润',
              secondHeading: '',
              summarize: arrs[0].projectTeamProfit,
              numberList: projectTeamProfit,
            },
          ];
          state.tableDatas2 = tableDatas2;
          // var tableDatas2: any = [];
          // tableDatas2 = [
          //   { indexs: '一、期初货币资金', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas.initialFunds), numberList: examList, },
          //   { indexs: '二、本期资金增加', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas[0].currentIncrease), numberList: currentIncrease, },
          //   { indexs: 1, firstHeading: '收款单', secondHeading: '账号出售', thirdHeading: '销货单', summarize: isZero(datas[0].zhcsBillOfSales), numberList: zhcsBillOfSales, },
          //   { indexs: 2, firstHeading: '收款单', secondHeading: '账号出售', thirdHeading: '退货单', summarize: isZero(datas[0].zhcsGoodsReturnedNote), numberList: zhcsGoodsReturnedNote, },
          //   { indexs: 3, firstHeading: '收款单', secondHeading: '运营订单', thirdHeading: '销货单', summarize: isZero(datas[0].yyddBillOfSales), numberList: yyddBillOfSales, },
          //   { indexs: 4, firstHeading: '收款单', secondHeading: '运营订单', thirdHeading: '退货单', summarize: isZero(datas[0].yyddGoodsReturnedNote), numberList: yyddGoodsReturnedNote, },
          //   { indexs: 5, firstHeading: '收款单', secondHeading: '其他收入', thirdHeading: '销货单', summarize: isZero(datas[0].qtsrBillOfSales), numberList: qtsrBillOfSales, },
          //   { indexs: 6, firstHeading: '收款单', secondHeading: '其他收入', thirdHeading: '退货单', summarize: isZero(datas[0].qtsrGoodsReturnedNote), numberList: qtsrGoodsReturnedNote, },
          //   { indexs: 7, firstHeading: '存取款单', secondHeading: '备用金借款归还', thirdHeading: '', summarize: isZero(datas[0].pettyCashRepayment), numberList: pettyCashRepayment, },
          //   { indexs: 8, firstHeading: '股东注资', secondHeading: '', thirdHeading: '', summarize: isZero(datas[0].gdCapitalInjection), numberList: gdCapitalInjection, },
          //   { indexs: '三、本期资金减少', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas[0].currentFundsReduce), numberList: currentFundsReduce, },
          //   { indexs: 1, firstHeading: '付款单', secondHeading: '收号放款(回收价+包赔费)', thirdHeading: '销货单', summarize: isZero(datas[0].shfkbillOfSales), numberList: shfkbillOfSales, },
          //   { indexs: 2, firstHeading: '付款单', secondHeading: '收号放款(回收价+包赔费)', thirdHeading: '退货单', summarize: isZero(datas[0].shfkGoodsReturnedNote), numberList: shfkGoodsReturnedNote, },
          //   { indexs: 3, firstHeading: '付款单', secondHeading: '费用支出-售后支出', thirdHeading: '销货单', summarize: isZero(datas[0].shzfBillOfSales), numberList: shzfBillOfSales, },
          //   { indexs: 4, firstHeading: '付款单', secondHeading: '费用支出-售后支出', thirdHeading: '退货单', summarize: isZero(datas[0].shzfGoodsReturnedNote), numberList: shzfGoodsReturnedNote, },
          //   { indexs: 5, firstHeading: '付款单', secondHeading: '费用支出-充值话费', thirdHeading: '销货单', summarize: isZero(datas[0].czhfBillOfSales), numberList: czhfBillOfSales, },
          //   { indexs: 6, firstHeading: '付款单', secondHeading: '费用支出-充值话费', thirdHeading: '退货单', summarize: isZero(datas[0].czhfGoodsReturnedNote), numberList: czhfGoodsReturnedNote, },
          //   { indexs: 7, firstHeading: '付款单', secondHeading: '费用支出-运营佣金', thirdHeading: '销货单', summarize: isZero(datas[0].yyyjBillOfSales), numberList: yyyjBillOfSales, },
          //   { indexs: 8, firstHeading: '付款单', secondHeading: '费用支出-运营佣金', thirdHeading: '退货单', summarize: isZero(datas[0].yyyjGoodsReturnedNote), numberList: yyyjGoodsReturnedNote, },
          //   { indexs: 9, firstHeading: '付款单', secondHeading: '费用支出-其他支出', thirdHeading: '销货单', summarize: isZero(datas[0].qtzfBillOfSales), numberList: qtzfBillOfSales, },
          //   { indexs: 10, firstHeading: '付款单', secondHeading: '费用支出-其他支出', thirdHeading: '退货单', summarize: isZero(datas[0].qtzfGoodsReturnedNote), numberList: qtzfGoodsReturnedNote, },
          //   { indexs: 11, firstHeading: '付款单', secondHeading: '运营订单', thirdHeading: '销货单', summarize: isZero(datas[0].yyddBillOfSalesByFk), numberList: yyddBillOfSalesByFk, },
          //   { indexs: 12, firstHeading: '付款单', secondHeading: '运营订单', thirdHeading: '退货单', summarize: isZero(datas[0].yyddGoodsReturnedNoteByFk), numberList: yyddGoodsReturnedNoteByFk, },
          //   { indexs: 13, firstHeading: '职工薪酬', secondHeading: '', thirdHeading: '', summarize: isZero(datas[0].payroll), numberList: payroll, },
          //   { indexs: 14, firstHeading: '存取款单', secondHeading: '备用金借款', thirdHeading: '', summarize: isZero(datas[0].byjBorrowMoney), numberList: byjBorrowMoney, },
          //   { indexs: 15, firstHeading: '股东分红', secondHeading: '', thirdHeading: '', summarize: isZero(datas[0].gdshareOutBonus), numberList: gdshareOutBonus, },
          //   { indexs: '四、本期资金增减净值', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas[0].currentFundsNetWorth), numberList: currentFundsNetWorth, },
          //   { indexs: '上期末资金增减净值', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas[0].lsatEndingFundsNetWorth), numberList: lsatEndingFundsNetWorth, },
          //   { indexs: '减免', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas[0].derate), numberList: derate, },
          //   { indexs: '本期累计资金增减净值', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas[0].atPresentAccumulatedFundsNetWorth), numberList: atPresentAccumulatedFundsNetWorth, },
          //   { indexs: '团队', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: '', numberList: examList, },
          //   { indexs: '累计未分配现金净值', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas.undistributedFundsSum), numberList: examList, },
          //   { indexs: '总经理分红现金净值', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas.zjlShareOutBonus), numberList: examList, },
          //   { indexs: '股东分红净值', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas.gdShareOutBonus), numberList: examList, },
          //   { indexs: '剩余未分配净值', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas.residueUndistributed), numberList: examList, },
          //   { indexs: '五、期末货币资金', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas.endingFunds), numberList: examList, },
          //   { indexs: '试算平衡', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas.trialBalancing), numberList: examList, },
          // ];
          state.tableDatas2 = tableDatas2;
        }
        emits('isLoading', false)
        state.loading2 = false;
      })
      .catch((error: any) => {
        // console.log('获取利润表-失败', error)//失败
        state.loading2 = false;
      })
  }, 100)
}

const objectSpanMethod = ({
  row,
  column,
  rowIndex,
  columnIndex,
}: SpanMethodProps) => {
  // 行合并
  if (rowIndex === 0 || rowIndex === 5 || rowIndex === 8 || rowIndex === 17 || rowIndex === 18 || rowIndex === 19) {
    if (columnIndex === 1) {
      return [1, 2]
    } else if (columnIndex === 2) {
      return [0, 0]
    }
  }
  // 列合并
  if (columnIndex === 1) {
    if (rowIndex === 1 || rowIndex === 3 || rowIndex === 6 || rowIndex === 9 || rowIndex === 11 || rowIndex === 13 || rowIndex === 15) {
      return {
        rowspan: 2,
        colspan: 1,
      }
    } else {
      return {
        rowspan: 0,
        colspan: 0,
      }
    }
  }
}

const reports2 = async () => {
  // var tabelStr = document.querySelector('#lrbTable').outerHTML;
  // instance?.proxy?.$getExportFile1(tabelStr, '利润表');// 表格类型
  // instance?.proxy?.$getExportFile2(tabelStr, '利润表');// html类型

  state.loading = true;
  console.log('---')
  let jsons = {
    "startAndEndTime": props.startAndEndTime.length > 0 ? props.startAndEndTime.split(',') : [instance?.proxy?.$getCurrentMonth().startTimestamp, instance?.proxy?.$getCurrentMonth().endTimestamp],
    "gameName": props.gameName || '',
    "department": props.department || ''
  };
  await lrbExportoApi(jsons)
    .then((res: any) => {
      console.log('利润表---导出', res)//成功
      // 创建一个隐藏的a链接
      const link = document.createElement('a');
      // 文件流转化为对应格式的文件
      const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
      // 把a标签隐藏
      link.style.display = 'none';
      link.href = URL.createObjectURL(blob);
      // 设置文件下载后的名称
      link.download = '利润表.xlsx';
      document.body.appendChild(link);
      link.click();
    })
    .catch((error: any) => {
      console.log('利润表---导出-失败', error)//失败
    }).finally(() => {
      state.loading = false;
    })
}

onMounted(() => {
  getTables();
})

// watch(() => props.department, (newVal) => {
//   state.department = newVal;
// })
// watch(() => props.gameName, (newVal) => {
//   state.gameName = newVal;
// })
// watch(() => props.startAndEndTime, (newVal) => {
//   state.startAndEndTime = newVal;
// })

</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
}

.conCenter {
  flex: 1;
  margin: 0 auto;
  padding-bottom: 100px;
  font-size: 15px;
  color: #111;
  word-break: break-all;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px dotted #333;
  background-color: #fff;
}

h2 {
  width: 100%;
  height: 60px;
  color: #fff;
  line-height: 60px;
  background-color: #7030a0;
}

.sectionLeft {
  width: 510px;
  line-height: 40px;
}

.sectionRight {
  flex: 1;
  background-color: #efefef;
  display: flex;
}

.lrbTable {
  :deep(&.el-table thead.is-group) {
    tr {
      th.el-table__cell {
        color: #833c0b;
        text-align: center;
        background-color: #fff;
      }

      &:nth-child(1) {
        th.el-table__cell {
          &:nth-child(2) {
            // text-indent: 180px;
            text-align: right;
            padding-right: 15px;
            border-right: none;
          }
        }
      }

      &:nth-child(2) {
        th.el-table__cell {
          &:nth-child(0) {
            border: none;
          }

          &:nth-child(1) {
            // text-indent: 180px;
            text-align: right;
            padding-right: 15px;
            border-right: none;
          }
        }
      }
    }
  }


  // :deep(&.el-table thead.is-group) {
  //   tr {
  //     th.el-table__cell {
  //       color: #111;
  //       text-align: center;
  //       background-color: #fff;
  //     }

  //     &:nth-child(1) {
  //       th.el-table__cell {
  //         &:nth-child(2) {
  //           padding-left: 100px;
  //           border: none;
  //         }

  //         &:last-child {
  //           border: none;
  //           position: relative;

  //           .cell {
  //             position: absolute;
  //             left: calc(50% - 16px);
  //             top: 30px;
  //             z-index: 99999;
  //           }
  //         }
  //       }
  //     }

  //     &:nth-child(2) {
  //       th.el-table__cell {
  //         &:nth-child(1) {
  //           padding-left: 100px;
  //           border-right: none;
  //         }

  //         &:last-child {
  //           display: none;
  //         }
  //       }
  //     }
  //   }
  // }

  // :deep(&.el-table thead.is-group tr:nth-child(1) th.el-table__cell) {
  //   &:nth-child(2) {
  //     width: 520px;
  //   }
  // }

  // :deep(&.el-table thead.is-group tr:nth-child(2) th.el-table__cell) {
  //   &:nth-child(1) {
  //     width: 520px;
  //   }
  // }

  :deep(&.el-table tbody) {
    tr:hover {
      td {
        background-color: #fff;
      }
    }

    tr:nth-child(1),
    tr:nth-child(6),
    tr:nth-child(9),
    tr:nth-child(20) {
      td {
        color: #fff;
        background-color: #7030a0;
      }

      &:hover {
        td {
          background-color: #7030a0;
        }
      }
    }

    td {
      text-align: center;
      vertical-align: middle;
      background-color: #fff;
    }
  }
}

.exportBtn {
  width: 95vw;
  margin: 0 auto 20px;
  padding: 10px 0 0;
  text-align: left;

  :deep(.el-button) {
    color: #fff;
    background-color: #7030a0;
    border-color: #7030a0;
    transform: scale(1.2);
  }
}
</style>