import { createStore } from "vuex";

export default createStore({
  state: {
    // 全局参数
    navbar: true,
    account: ''
  },
  getters: {
    // get方法
    navbar: state => state.navbar,
  },
  mutations: {
    // set方法
    SET_NAVBAR: (state, navbar) => {
      state.navbar = navbar;
    },
    SET_ACCOUNT: (state, account) => {
      state.account = account;
    }
  },
  actions: {
    // 处理state的方法体
  },
  modules: {},
});
