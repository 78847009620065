import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "../store";
import loginView from "../views/login/loginIndex.vue";
import homeView from "../views/home/homeIndex.vue";
import accountView from "../views/account/accountIndex.vue";
import flowView from "../views/flow/flowIndex.vue";
import reportView from "../views/report/reportIndex.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "/",
    component: homeView,
    meta: {
      title: "首页",
      // keepAlive: true,
      // useTransition: true
    },
  },
  {
    path: "/accountView",
    name: "accountView",
    component: accountView,
    // meta: { transition: 'slide' }
    meta: {
      title: "账号管理",
      // keepAlive: true,
      // useTransition: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/loginView",
    name: "loginView",
    component: loginView,
    // meta: { transition: 'slide' }
    meta: {
      title: "",
      // keepAlive: true,
      // useTransition: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/flowView",
    name: "/flowView",
    component: flowView,
    // meta: { transition: 'slide' }
    meta: {
      title: "流程管理",
      // keepAlive: true,
      // useTransition: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/reportView",
    name: "reportView",
    component: reportView,
    // meta: { transition: 'slide' }
    meta: {
      title: "",
      // keepAlive: true,
      // useTransition: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const publicRoutes = ["/loginView", "/reportView"];

function isUserLoggedIn() {
  console.log("是否登录", !!localStorage.getItem("token"));
  return !!localStorage.getItem("token");
}

// 全局前置守卫
router.beforeEach((to, from, next) => {
  if (publicRoutes.includes(to.path)) {
    store.commit("SET_NAVBAR", false);
    store.commit("SET_ACCOUNT", "");
    next();
  } else {
    if (to.path !== "/loginView" && !isUserLoggedIn()) {
      store.commit("SET_NAVBAR", false);
      store.commit("SET_ACCOUNT", "");
      next("/loginView");
    } else if (
      to.path == "/accountView" &&
      localStorage.getItem("account") !== "admin"
    ) {
      store.commit("SET_NAVBAR", false);
      store.commit("SET_ACCOUNT", "");
      next("/");
    } else {
      store.commit("SET_NAVBAR", true);
      store.commit("SET_ACCOUNT", localStorage.getItem("account"));
      next();
    }
  }
});

// router.afterEach((to, from) => {
//   if (to.name !== 'loginView' && isUserLoggedIn()) {
//     // 如果不是登录页面且用户已登录，则替换登录页面路由
//     router.replace('/');
//   }
// });

export default router;
