<template>
  <el-scrollbar>
    <div class="container">
      <div v-if="state.loading2" style="position: fixed; top: 70px; width: 100vw; height: 100vh; z-index: 9999"
        v-loading="state.loading2" element-loading-text="加载中..." :element-loading-svg="svg"
        element-loading-custom-class="custom-loading-svg" element-loading-svg-view-box="-10, -10, 50, 50"></div>
      <div class="exportBtn"><el-button @click="reports1">导出</el-button></div>
      <div class="conCenter" :style="{ width: `95vw` }" id="xjllbTable">
        <h2>现金流量表</h2>
        <div class="dates">
          <h3>日期：<span>{{ state.dates }}</span></h3>
        </div>
        <el-table class="xjllbTable" :data="state.tableDatas1" :span-method="objectSpanMethod"
          @cell-click="handleCellClick" style="text-align: center;" v-loading="state.loading"
          element-loading-text="Loading..." :element-loading-spinner="svg"
          element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="rgba(255, 255, 255, 0.8)">
          <el-table-column label="行次" prop="indexs" minWidth="90" />
          <el-table-column label="">
            <el-table-column label="" prop="firstHeading" minWidth="100" />
          </el-table-column>
          <el-table-column label="项目组">
            <el-table-column label="游戏名" prop="secondHeading" minWidth="180" />
          </el-table-column>
          <el-table-column label="">
            <el-table-column label="" prop="thirdHeading" minWidth="100" />
          </el-table-column>
          <el-table-column label="汇总金额" prop="summarize" minWidth="130">
            <template #default="scope">{{ scope.row.summarize }}</template>
          </el-table-column>
          <el-table-column v-for="(item, index) in state.deptList" :key="index" :label="item.label" minWidth="100">
            <el-table-column v-for="(item2, index2) in item.child" :key="index2" :label="item2.label" minWidth="100"
              :prop="item.label + (item2.label ? '&' + item2.label : '')">
              <template #default="scope">{{ isZero(scope.row.numberList[item2.indexs - 1].cells) }}</template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 表单 -->
    <popupForm :dialogReportVisible="state.dialogReportVisible" :dialogData="state.dialogData"
      @toggleReportDialog="closeReportDialog" />

  </el-scrollbar>
</template>
<script lang="ts" setup>
import { ref, onMounted, provide, reactive, inject, computed, getCurrentInstance } from 'vue';
import { progressProps, type TabsPaneContext } from 'element-plus';
import popupForm from "./popupForm.vue";
import { xjllbApi, xjllbInfoApi, xjllbExportoApi } from '../../api/index';
import { fa, it } from 'element-plus/es/locale';
import { watch } from 'vue';
const instance = getCurrentInstance();
const moment = require("moment");

interface Props {
  activeName?: string,//当前tabs
  department?: string,//所在部门
  gameName?: string,//游戏名称
  startAndEndTime?: any,//查询时间
}
const props = withDefaults(defineProps<Props>(), {
  activeName: '',//当前tabs
  department: '',//所在部门
  gameName: '',//游戏名称
  startAndEndTime: [],//查询时间
})

const svg = `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `

const state: {
  dates: string,//当前年月
  deptList: any,//部门列表
  tableDatas1: any,//表格数据
  gameLen: number,//游戏数量
  dialogReportVisible: boolean,//弹窗显隐
  dialogData: any,//弹窗内容
  loading: boolean,//loading
  formUuid: any,//表单id
  loading2: boolean,
} = reactive({
  dates: '',//当前年月
  deptList: [],//部门列表
  tableDatas1: [],//表格数据
  gameLen: 0,//游戏数量
  dialogReportVisible: false,//弹窗显隐
  dialogData: {},//弹窗内容
  loading: false,//loading
  formUuid: [
    { title: '收款单', id: 'FORM-7EAD65982C5A4EF5B62914B2EB723819C88W' },
    { title: '付款单', id: 'FORM-2D249B2A992143299AA668A0B3353BF3ULJB' },
    { title: '存取款单', id: 'FORM-4D611F15CF144927A9056B191C196E91B3XC' },
    { title: '职工薪酬', id: 'FORM-2A0F6BF764E848258213892C75CB57A0PFAZ' },
    { title: '股东分红', id: 'FORM-2509394B550240DA985C192C6A7489FFFE1O' },
  ],//表单id
  loading2: false,
})

// 点击单元格，展示弹窗
const handleCellClick = (row: any, column: any, cell: any, event: any) => {
  let type = column.property.split('&')[0];
  if (row.firstHeading === '' || type === "indexs" || type === "firstHeading" || type === "secondHeading" || type === "thirdHeading" || type === "summarize") return;
  state.loading = true;
  let formIdList = [
    { title: '收款单', id: 'FORM-7EAD65982C5A4EF5B62914B2EB723819C88W' },
    { title: '存取款单', id: 'FORM-4D611F15CF144927A9056B191C196E91B3XC' },
    { title: '股东注资', id: 'FORM-8F7F2488F38D4C1E8B4071E5953609DF4IMZ' },
    { title: '付款单', id: 'FORM-2D249B2A992143299AA668A0B3353BF3ULJB' },
    { title: '职工薪酬', id: 'FORM-2A0F6BF764E848258213892C75CB57A0PFAZ' },
    { title: '股东分红', id: 'FORM-2509394B550240DA985C192C6A7489FFFE1O' },
  ];
  let deptList = [
    { title: '【理想】组', id: '965749065' },
    { title: '【晨光】组', id: '965350885' },
    { title: '【永兴】组', id: '965467720' },
    { title: '【浚成】组', id: '965498661' },
    { title: '山东玩趣网络科技有限公司', id: '844139550' },
  ];
  var startAndEndTime = props.startAndEndTime.length > 0 ? props.startAndEndTime.split(',') : [instance?.proxy?.$getCurrentMonth().startTimestamp, instance?.proxy?.$getCurrentMonth().endTimestamp];//时间
  var gameName = column.property.split('&')[1];//游戏名称
  var byjType = '';//备用金借款归还/备用金借款
  var expendType = row.secondHeading.indexOf('费用支出') !== -1 ? row.secondHeading.split('-')[1] : '';//支出类型
  var billsType = '';//单据类型
  if (row.firstHeading === '股东注资' || row.firstHeading === '股东分红') {
    billsType = row.firstHeading;
  } else if (row.firstHeading === '职工薪酬') {
    billsType = '发放薪资';
  } else if (row.thirdHeading === '销货单') {
    if (row.secondHeading.indexOf('账号出售') !== -1 || row.secondHeading.indexOf('收号放款') !== -1 || row.secondHeading.indexOf('运营订单') !== -1 || row.secondHeading.indexOf('其他收入') !== -1 || row.secondHeading.indexOf('费用支出') !== -1) {
      billsType = row.secondHeading.slice(0, 4);
    }
  } else if (row.thirdHeading === '退货单') {
    if (row.secondHeading.indexOf('账号出售') !== -1 || row.secondHeading.indexOf('收号放款') !== -1 || row.secondHeading.indexOf('运营订单') !== -1) {
      billsType = row.secondHeading.slice(0, 4) + '退货单';
    } else if (row.secondHeading.indexOf('其他收入') !== -1 || row.secondHeading.indexOf('费用支出') !== -1) {
      billsType = row.secondHeading.slice(0, 4) + '红单';
    }
  } else {
    billsType = '';//存取款单
    byjType = row.secondHeading;//备用金借款归还/备用金借款
  }
  var formId = formIdList.filter(item => item.title === row.firstHeading)[0].id;//表单id
  var department = deptList.filter(item => item.title === column.property.split('&')[0])[0].id;//部门
  let jsons = {
    startAndEndTime: startAndEndTime,//时间
    gameName: gameName,//游戏名称
    expendType: expendType,//支出类型
    billsType: billsType,//单据类型
    formId: formId,//表单id
    department: department,//部门
    byjType: byjType,//备用金借款归还/备用金借款
  };
  xjllbInfoApi(jsons)
    .then((res: any) => {
      // console.log('获取报表详情-成功', res)//成功
      let datas = JSON.parse(JSON.stringify(res.result.result));
      state.dialogData = {
        title: row.firstHeading + (row.secondHeading ? '-' : '') + row.secondHeading + (row.thirdHeading ? '-' : '') + row.thirdHeading,
        data: datas
      };
    })
    .catch((error: any) => {
      // console.log('获取报表详情-失败', error)//失败
      state.dialogData = {
        title: row.firstHeading + (row.secondHeading ? '-' : '') + row.secondHeading + (row.thirdHeading ? '-' : '') + row.thirdHeading,
        data: []
      };
    }).finally(() => {
      state.dialogReportVisible = true;
      state.loading = false;
    })
}

// 关闭弹窗
const closeReportDialog = () => {
  state.dialogReportVisible = false;
}

const emits = defineEmits(['isLoading']);
const getTables = () => {
  setTimeout(async () => {
    state.loading2 = true;
    let jsons = {
      "startAndEndTime": props.startAndEndTime.length > 0 ? props.startAndEndTime.split(',') : [instance?.proxy?.$getCurrentMonth().startTimestamp, instance?.proxy?.$getCurrentMonth().endTimestamp],
      "gameName": props.gameName || '',
      "department": props.department || ''
    };
    await xjllbApi(jsons)
      .then((res: any) => {
        // console.log('获取现金流量表-成功', res)//成功
        let datas = JSON.parse(JSON.stringify(res.result));
        if (datas && datas.cashFlowStatementSonList.length) {
          let arrs = JSON.parse(JSON.stringify(datas.cashFlowStatementSonList));
          var currentIncrease = [], zhcsBillOfSales = [], zhcsGoodsReturnedNote = [], yyddBillOfSales = [], yyddGoodsReturnedNote = [], qtsrBillOfSales = [],
            qtsrGoodsReturnedNote = [], byjBorrowMoney = [], gdCapitalInjection = [], currentFundsReduce = [], shfkbillOfSales = [], shfkGoodsReturnedNote = [],
            shzfBillOfSales = [], shzfGoodsReturnedNote = [], czhfBillOfSales = [], czhfGoodsReturnedNote = [], yyyjBillOfSales = [], yyyjGoodsReturnedNote = [],
            qtzfBillOfSales = [], qtzfGoodsReturnedNote = [], yyddBillOfSalesByFk = [], yyddGoodsReturnedNoteByFk = [], payroll = [], pettyCashRepayment = [],
            gdshareOutBonus = [], currentFundsNetWorth = [], lsatEndingFundsNetWorth = [], derate = [], atPresentAccumulatedFundsNetWorth = [];
          const temp: any = {};
          var indexs = 0;
          for (let i in arrs) {
            if (arrs[i].department && arrs[i].department !== '汇总金额') {
              currentIncrease.push({ cells: arrs[i].currentIncrease })
              zhcsBillOfSales.push({ cells: arrs[i].zhcsBillOfSales })
              zhcsGoodsReturnedNote.push({ cells: arrs[i].zhcsGoodsReturnedNote })
              yyddBillOfSales.push({ cells: arrs[i].yyddBillOfSales })
              yyddGoodsReturnedNote.push({ cells: arrs[i].yyddGoodsReturnedNote })
              qtsrBillOfSales.push({ cells: arrs[i].qtsrBillOfSales })
              qtsrGoodsReturnedNote.push({ cells: arrs[i].qtsrGoodsReturnedNote })
              byjBorrowMoney.push({ cells: arrs[i].byjBorrowMoney })
              gdCapitalInjection.push({ cells: arrs[i].gdCapitalInjection })
              currentFundsReduce.push({ cells: arrs[i].currentFundsReduce })
              shfkbillOfSales.push({ cells: arrs[i].shfkbillOfSales })
              shfkGoodsReturnedNote.push({ cells: arrs[i].shfkGoodsReturnedNote })
              shzfBillOfSales.push({ cells: arrs[i].shzfBillOfSales })
              shzfGoodsReturnedNote.push({ cells: arrs[i].shzfGoodsReturnedNote })
              czhfBillOfSales.push({ cells: arrs[i].czhfBillOfSales })
              czhfGoodsReturnedNote.push({ cells: arrs[i].czhfGoodsReturnedNote })
              yyyjBillOfSales.push({ cells: arrs[i].yyyjBillOfSales })
              yyyjGoodsReturnedNote.push({ cells: arrs[i].yyyjGoodsReturnedNote })
              qtzfBillOfSales.push({ cells: arrs[i].qtzfBillOfSales })
              qtzfGoodsReturnedNote.push({ cells: arrs[i].qtzfGoodsReturnedNote })
              yyddBillOfSalesByFk.push({ cells: arrs[i].yyddBillOfSalesByFk })
              yyddGoodsReturnedNoteByFk.push({ cells: arrs[i].yyddGoodsReturnedNoteByFk })
              payroll.push({ cells: arrs[i].payroll })
              pettyCashRepayment.push({ cells: arrs[i].pettyCashRepayment })
              gdshareOutBonus.push({ cells: arrs[i].gdshareOutBonus })
              currentFundsNetWorth.push({ cells: arrs[i].currentFundsNetWorth })
              lsatEndingFundsNetWorth.push({ cells: arrs[i].lsatEndingFundsNetWorth })
              derate.push({ cells: arrs[i].derate })
              atPresentAccumulatedFundsNetWorth.push({ cells: arrs[i].atPresentAccumulatedFundsNetWorth })
              let key = arrs[i].department; // 判断依据，利用对象的属性不能重复进行判断。
              if (key) {
                if (temp[key]) {
                  indexs++;
                  temp[key].label = arrs[i].department;
                  temp[key].child.push({ indexs: indexs, label: arrs[i].gameName });// 游戏名称

                } else {
                  temp[key] = {};
                  indexs++;
                  temp[key].label = arrs[i].department;
                  temp[key].child = [];
                  temp[key].child.push({ indexs: indexs, label: arrs[i].gameName ? arrs[i].gameName : '' });// 游戏名称
                }
              }
            }
          }
          const newArry = [];
          for (let k in temp) {
            newArry.push(temp[k]);
          };
          state.deptList = newArry;//所有的部门+游戏，做表头
          state.gameLen = indexs;
          var examList = [];
          for (var i = 0; i < indexs; i++) {
            examList.push({ cells: 0 })
          }
          var tableDatas1: any = [];
          tableDatas1 = [
            { indexs: '一、期初货币资金', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas.initialFunds), numberList: examList, },
            { indexs: '二、本期资金增加', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas.cashFlowStatementSonList[0].currentIncrease), numberList: currentIncrease, },
            { indexs: 1, firstHeading: '收款单', secondHeading: '账号出售', thirdHeading: '销货单', summarize: isZero(datas.cashFlowStatementSonList[0].zhcsBillOfSales), numberList: zhcsBillOfSales, },
            { indexs: 2, firstHeading: '收款单', secondHeading: '账号出售', thirdHeading: '退货单', summarize: isZero(datas.cashFlowStatementSonList[0].zhcsGoodsReturnedNote), numberList: zhcsGoodsReturnedNote, },
            { indexs: 3, firstHeading: '收款单', secondHeading: '运营订单', thirdHeading: '销货单', summarize: isZero(datas.cashFlowStatementSonList[0].yyddBillOfSales), numberList: yyddBillOfSales, },
            { indexs: 4, firstHeading: '收款单', secondHeading: '运营订单', thirdHeading: '退货单', summarize: isZero(datas.cashFlowStatementSonList[0].yyddGoodsReturnedNote), numberList: yyddGoodsReturnedNote, },
            { indexs: 5, firstHeading: '收款单', secondHeading: '其他收入', thirdHeading: '销货单', summarize: isZero(datas.cashFlowStatementSonList[0].qtsrBillOfSales), numberList: qtsrBillOfSales, },
            { indexs: 6, firstHeading: '收款单', secondHeading: '其他收入', thirdHeading: '退货单', summarize: isZero(datas.cashFlowStatementSonList[0].qtsrGoodsReturnedNote), numberList: qtsrGoodsReturnedNote, },
            { indexs: 7, firstHeading: '存取款单', secondHeading: '备用金借款归还', thirdHeading: '', summarize: isZero(datas.cashFlowStatementSonList[0].pettyCashRepayment), numberList: pettyCashRepayment, },
            { indexs: 8, firstHeading: '股东注资', secondHeading: '', thirdHeading: '', summarize: isZero(datas.cashFlowStatementSonList[0].gdCapitalInjection), numberList: gdCapitalInjection, },
            { indexs: '三、本期资金减少', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas.cashFlowStatementSonList[0].currentFundsReduce), numberList: currentFundsReduce, },
            { indexs: 1, firstHeading: '付款单', secondHeading: '收号放款(回收价+包赔费)', thirdHeading: '销货单', summarize: isZero(datas.cashFlowStatementSonList[0].shfkbillOfSales), numberList: shfkbillOfSales, },
            { indexs: 2, firstHeading: '付款单', secondHeading: '收号放款(回收价+包赔费)', thirdHeading: '退货单', summarize: isZero(datas.cashFlowStatementSonList[0].shfkGoodsReturnedNote), numberList: shfkGoodsReturnedNote, },
            { indexs: 3, firstHeading: '付款单', secondHeading: '费用支出-售后支出', thirdHeading: '销货单', summarize: isZero(datas.cashFlowStatementSonList[0].shzfBillOfSales), numberList: shzfBillOfSales, },
            { indexs: 4, firstHeading: '付款单', secondHeading: '费用支出-售后支出', thirdHeading: '退货单', summarize: isZero(datas.cashFlowStatementSonList[0].shzfGoodsReturnedNote), numberList: shzfGoodsReturnedNote, },
            { indexs: 5, firstHeading: '付款单', secondHeading: '费用支出-充值话费', thirdHeading: '销货单', summarize: isZero(datas.cashFlowStatementSonList[0].czhfBillOfSales), numberList: czhfBillOfSales, },
            { indexs: 6, firstHeading: '付款单', secondHeading: '费用支出-充值话费', thirdHeading: '退货单', summarize: isZero(datas.cashFlowStatementSonList[0].czhfGoodsReturnedNote), numberList: czhfGoodsReturnedNote, },
            { indexs: 7, firstHeading: '付款单', secondHeading: '费用支出-运营佣金', thirdHeading: '销货单', summarize: isZero(datas.cashFlowStatementSonList[0].yyyjBillOfSales), numberList: yyyjBillOfSales, },
            { indexs: 8, firstHeading: '付款单', secondHeading: '费用支出-运营佣金', thirdHeading: '退货单', summarize: isZero(datas.cashFlowStatementSonList[0].yyyjGoodsReturnedNote), numberList: yyyjGoodsReturnedNote, },
            { indexs: 9, firstHeading: '付款单', secondHeading: '费用支出-其他支出', thirdHeading: '销货单', summarize: isZero(datas.cashFlowStatementSonList[0].qtzfBillOfSales), numberList: qtzfBillOfSales, },
            { indexs: 10, firstHeading: '付款单', secondHeading: '费用支出-其他支出', thirdHeading: '退货单', summarize: isZero(datas.cashFlowStatementSonList[0].qtzfGoodsReturnedNote), numberList: qtzfGoodsReturnedNote, },
            { indexs: 11, firstHeading: '付款单', secondHeading: '运营订单', thirdHeading: '销货单', summarize: isZero(datas.cashFlowStatementSonList[0].yyddBillOfSalesByFk), numberList: yyddBillOfSalesByFk, },
            { indexs: 12, firstHeading: '付款单', secondHeading: '运营订单', thirdHeading: '退货单', summarize: isZero(datas.cashFlowStatementSonList[0].yyddGoodsReturnedNoteByFk), numberList: yyddGoodsReturnedNoteByFk, },
            { indexs: 13, firstHeading: '职工薪酬', secondHeading: '', thirdHeading: '', summarize: isZero(datas.cashFlowStatementSonList[0].payroll), numberList: payroll, },
            { indexs: 14, firstHeading: '存取款单', secondHeading: '备用金借款', thirdHeading: '', summarize: isZero(datas.cashFlowStatementSonList[0].byjBorrowMoney), numberList: byjBorrowMoney, },
            { indexs: 15, firstHeading: '股东分红', secondHeading: '', thirdHeading: '', summarize: isZero(datas.cashFlowStatementSonList[0].gdshareOutBonus), numberList: gdshareOutBonus, },
            { indexs: '四、本期资金增减净值', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas.cashFlowStatementSonList[0].currentFundsNetWorth), numberList: currentFundsNetWorth, },
            { indexs: '上期末资金增减净值', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas.cashFlowStatementSonList[0].lsatEndingFundsNetWorth), numberList: lsatEndingFundsNetWorth, },
            { indexs: '减免', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas.cashFlowStatementSonList[0].derate), numberList: derate, },
            { indexs: '本期累计资金增减净值', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas.cashFlowStatementSonList[0].atPresentAccumulatedFundsNetWorth), numberList: atPresentAccumulatedFundsNetWorth, },
            { indexs: '团队', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: '', numberList: examList, },
            { indexs: '累计未分配现金净值', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas.undistributedFundsSum), numberList: examList, },
            { indexs: '总经理分红现金净值', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas.zjlShareOutBonus), numberList: examList, },
            { indexs: '股东分红净值', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas.gdShareOutBonus), numberList: examList, },
            { indexs: '剩余未分配净值', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas.residueUndistributed), numberList: examList, },
            { indexs: '五、期末货币资金', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas.endingFunds), numberList: examList, },
            { indexs: '试算平衡', firstHeading: '', secondHeading: '', thirdHeading: '', summarize: isZero(datas.trialBalancing), numberList: examList, },
          ];
          state.tableDatas1 = tableDatas1;
        }
        emits('isLoading', false)
        state.loading2 = false;
      })
      .catch((error: any) => {
        // console.log('获取现金流量表-失败', error)//失败
        state.loading2 = false;
      })
  }, 100)
}

const isZero = (val: any) => {
  return (val === '0.00' || val === '0') ? '' : val
}

const objectSpanMethod = ({
  row,
  column,
  rowIndex,
  columnIndex,
}: SpanMethodProps) => {
  // 行合并
  if (rowIndex === 0) {
    if (columnIndex === 0) {
      return [1, 4]
    } else if (columnIndex === 4) {
      return [1, state.gameLen + 1]
    } else {
      return [0, 0]
    }
  } else if (rowIndex === 1 || rowIndex === 10 || rowIndex === 26 || rowIndex === 27 || rowIndex === 28 || rowIndex === 29) {
    if (columnIndex === 0) {
      return [1, 4]
    } else if (columnIndex === 1 || columnIndex === 2 || columnIndex === 3) {
      return [0, 0]
    }
  } else if (rowIndex === 8 || rowIndex === 24) {
    if (columnIndex === 2) {
      return [1, 2]
    } else if (columnIndex === 3) {
      return [0, 0]
    }
  } else if (rowIndex === 9 || rowIndex === 23 || rowIndex === 25) {
    if (columnIndex === 1) {
      return [1, 3]
    } else if (columnIndex === 2 || columnIndex === 3) {
      return [0, 0]
    }
  } else if (rowIndex === 30) {
    if (columnIndex === 0) {
      return [1, state.gameLen + 5]
    } else {
      return [0, 0]
    }
  } else if (rowIndex > 30) {
    if (columnIndex === 0) {
      return [1, 4]
    } else if (columnIndex === 4) {
      return [1, state.gameLen + 1]
    } else {
      return [0, 0]
    }
  }
  // 列合并
  if (columnIndex === 1) {
    if (rowIndex === 2) {
      return {
        rowspan: 6,
        colspan: 1,
      }
    }
    else if (rowIndex < 8) {
      return {
        rowspan: 0,
        colspan: 0,
      }
    }
    else if (rowIndex === 11) {
      return {
        rowspan: 12,
        colspan: 1,
      }
    }
    else if (rowIndex > 11 && rowIndex < 23) {
      return {
        rowspan: 0,
        colspan: 0,
      }
    }
  } else if (columnIndex === 2) {
    if (rowIndex === 2 || rowIndex === 4 || rowIndex === 6 || rowIndex === 11 || rowIndex === 13 || rowIndex === 15 || rowIndex === 17 || rowIndex === 19 || rowIndex === 21 || rowIndex === 24) {
      return {
        rowspan: 2,
        colspan: 1,
      }
    } else {
      return {
        rowspan: 0,
        colspan: 0,
      }
    }
  }
}

// 导出
const reports1 = async () => {
  state.loading = true;
  console.log('---')
  let jsons = {
    "startAndEndTime": props.startAndEndTime.length > 0 ? props.startAndEndTime.split(',') : [instance?.proxy?.$getCurrentMonth().startTimestamp, instance?.proxy?.$getCurrentMonth().endTimestamp],
    "gameName": props.gameName || '',
    "department": props.department || ''
  };
  await xjllbExportoApi(jsons)
    .then((res: any) => {
      console.log('现金流量表---导出', res)//成功
      // 创建一个隐藏的a链接
      const link = document.createElement('a');
      // 文件流转化为对应格式的文件
      const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
      // 把a标签隐藏
      link.style.display = 'none';
      link.href = URL.createObjectURL(blob);
      // 设置文件下载后的名称
      link.download = '现金流量表.xlsx';
      document.body.appendChild(link);
      link.click();
    })
    .catch((error: any) => {
      console.log('现金流量表---导出-失败', error)//失败
    }).finally(() => {
      state.loading = false;
    })
  // var tabelStr = document.querySelector('#xjllbTable').outerHTML;
  // instance?.proxy?.$getExportFile1(tabelStr, '现金流量表');// 表格类型
  // instance?.proxy?.$getExportFile2(tabelStr, '现金流量表');// html类型
}

onMounted(() => {
  setTimeout(() => {
    if (props.startAndEndTime.length > 0) {
      var times = moment(props.startAndEndTime.slice(0, 13) * 1).format('YYYY-MM-DD').split('-');
      state.dates = times[0] + '年' + times[1] + '月';
    } else {
      state.dates = instance?.proxy?.$getCurrentDate().year + '年' + instance?.proxy?.$getCurrentDate().month + '月';
    }
  }, 1500)
  getTables();
})

// watch(() => props.department, (newVal) => {
//   state.department = newVal;
// })
// watch(() => props.gameName, (newVal) => {
//   state.gameName = newVal;
// })
// watch(() => props.startAndEndTime, (newVal) => {
//   state.startAndEndTime = newVal;
// })

</script>

<style lang="less" scoped>
.conCenter {
  flex: 1;
  margin: 0 auto;
  padding-bottom: 100px;
  font-size: 15px;
  color: #833c0b;
  word-break: break-all;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px dotted #70ad47;
  background-color: #fff;
}

h2 {
  width: 100%;
  height: 60px;
  color: #fff;
  line-height: 60px;
  background-color: #70ad47;
}

.dates {
  width: 100%;
  height: 30px;
  text-align: left;
  line-height: 30px;
  text-indent: 5px;

  span {
    padding: 0 5px;
  }
}

.sectionLeft {
  width: 510px;
  line-height: 40px;
}

.sectionRight {
  flex: 1;
  background-color: #efefef;
  display: flex;
}

.xjllbTable {
  :deep(&.el-table .el-table__header) {
    background-color: #a8d08d;
  }

  :deep(&.el-table thead.is-group) {
    tr {
      th.el-table__cell {
        color: #833c0b;
        text-align: center;
        background-color: #a8d08d;
        border-color: #70ad47;
      }

      &:nth-child(1) {
        th.el-table__cell {

          &:nth-child(2),
          &:nth-child(3) {
            border-right: none;
          }
        }
      }

      &:nth-child(2) {
        th.el-table__cell {
          &:nth-child(0) {
            border: none;
          }

          &:nth-child(1),
          &:nth-child(2) {
            border-right: none;
          }
        }
      }
    }
  }

  :deep(&.el-table tbody) {

    tr:nth-child(5),
    tr:nth-child(22) {

      td:nth-child(2),
      td:nth-child(3) {
        background-color: #ffff00;
      }
    }

    tr:nth-child(6),
    tr:nth-child(10),
    tr:nth-child(23),
    tr:nth-child(26) {

      td:nth-child(2) {
        background-color: #ffff00;
      }
    }



    tr:hover {
      td {
        background-color: #fff;
      }
    }

    tr:nth-child(1),
    tr:nth-child(36) {
      td {
        font-weight: bold;
      }
    }

    tr:nth-child(2),
    tr:nth-child(11),
    tr:nth-child(27),
    tr:nth-child(31),
    tr:nth-child(37) {
      td {
        font-weight: bold;
        background-color: #e2efda;
      }

      &:hover {
        td {
          background-color: #e2efda;
        }
      }
    }

    tr:nth-child(37) {
      td:nth-child(2) .cell {
        color: #c00000;
      }
    }

    tr:nth-child(28) {
      td:nth-child(1) {
        font-size: 15px;
        color: #70ad47;
        font-weight: bold;
      }
    }

    td {
      color: #833c0b;
      text-align: center;
      vertical-align: middle;
      background-color: #fff;
      border-color: #e2efda;
      cursor: pointer;
    }
  }
}

:deep(.el-loading-spinner) {
  top: 300px;
}

.container {
  display: flex;
  flex-direction: column;
}

.exportBtn {
  width: 95vw;
  margin: 0 auto 20px;
  padding: 10px 0 0;
  text-align: left;

  :deep(.el-button) {
    color: #fff;
    background-color: #70ad47;
    border-color: #70ad47;
    transform: scale(1.2);
  }
}
</style>