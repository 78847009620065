import type { App } from "vue";

const fn = () => "";

// 当前年月日
const currentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1 < 10 ? '0' + today.getMonth() + 1 : today.getMonth() + 1;
  const day = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
  return { today, year, month, day }
}

// 本月起始
const currentMonth = () => {
  const now = new Date();
  const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const startTimestamp = startOfMonth.getTime();
  const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
  endOfMonth.setMilliseconds(endOfMonth.getMilliseconds() - 1);
  const endTimestamp = endOfMonth.getTime();
  return { startTimestamp, endTimestamp }
}

// 导出附件--表格类型
const exportFile1 = (tabelStr: any, title: string) => {
  var uri = 'data:application/vnd.ms-excel;base64,';
  var exportTemplate = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
    <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
          <x:Name>${tabelStr}</x:Name>
                  <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
           </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
    </head>
    <body>
    ${tabelStr}
    </body>
    </html>`;
  var blob = new Blob([exportTemplate], { type: "application/vnd.ms-excel; charset=utf-8" });
  var link = document.createElement("a");
  link.href = uri + base64(exportTemplate);
  link.download = title + ".xls";
  link.innerHTML = title + "xls"
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

// 导出附件--HTML类型
const exportFile2 = (tabelStr: any, title: string) => {
  var xlsContent = `<html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel"
    xmlns="http://www.w3.org/TR/REC-html40">
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="ProgId" content="Excel.Sheet" /> 
  </head>
  <body>${tabelStr}</body>
  </html>`;
  var blob = new Blob([xlsContent], { type: "application/vnd.ms-excel; charset=utf-8" });
  var link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = title + ".xls";
  link.innerHTML = title + "xls"
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const base64 = (s: any) => {
  return window.btoa(unescape(encodeURIComponent(s)))
};

export default {
  install(app: App) {
    app.config.globalProperties.$env = "dev";
    app.config.globalProperties.$fishingcat = fn;
    app.config.globalProperties.$getCurrentDate = currentDate;
    app.config.globalProperties.$getCurrentMonth = currentMonth;
    app.config.globalProperties.$getExportFile1 = exportFile1;
    app.config.globalProperties.$getExportFile2 = exportFile2;
  },
};

declare module "@vue/runtime-core" {
  export interface ComponentCustomProperties {
    $env: string;
    $fishingcat: () => string;
    $getCurrentDate: () => any;
    $getCurrentMonth: () => any;
    $getExportFile1: (tabelStr: any, title: any) => any;
    $getExportFile2: (tabelStr: any, title: any) => any;
  }
}
