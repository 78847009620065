<template>
    <!-- 对话框表单 -->
    <el-dialog class="yidaDialog" v-model="state.dialogFormVisible" @close="toggledialogVisible" width="60%">
        <template #header>{{ state.flowMenu }}</template>
        <el-scrollbar max-height="65vh">
            <!-- 账号出售 -->
            <el-form ref="relevanceRef" :model="state.relevanceInfo" v-if="state.flowMenu === '账号出售'">
                <el-collapse v-model="yidaActiveNames" @change="handleyidaChange" class="yida-collapse">
                    <el-collapse-item title="状态信息分组" name="1">
                        <el-row>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="核销状态：">
                                    <p>{{ state.relevanceInfo.radioField_lyo4e3uc }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="收款状态：">
                                    <p>{{ state.relevanceInfo.radioField_lyo4e3u8 }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="基本信息分组" name="2">
                        <el-row>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据类型：">
                                    <p>{{ state.relevanceInfo.radioField_ly58t1vq }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据日期：">
                                    <p>{{ state.relevanceInfo.dateField_lyiegp55 ?
        timestampToDate(state.relevanceInfo.dateField_lyiegp55) : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据编号：">
                                    <p>{{ state.relevanceInfo.textField_ly73ykjc }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="客户：">
                                    <p>{{ state.relevanceInfo.associationFormField_ly5ifarv_id ?
        tranString(state.relevanceInfo.associationFormField_ly5ifarv_id) : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="业务员：">
                                    <p>{{ state.relevanceInfo.employeeField_lycl1p0g ?
        state.relevanceInfo.employeeField_lycl1p0g[0] : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="备注：">
                                    <p class="yidaDialogRemark">{{ state.relevanceInfo.textField_lyo4e3tf }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24" :xs="{ span: 24 }">
                                <el-form-item label="">
                                    <el-table :data="state.relevanceInfo.tableField_lycge4cj" border
                                        style="width: 100%">
                                        <el-table-column prop="departmentSelectField_lz9povl6" label="所在部门"
                                            minWidth="120" />
                                        <el-table-column prop="selectField_ltdygwvr" label="游戏名称" minWidth="120" />
                                        <el-table-column prop="textField_lyo4e3tk" label="游戏编号" minWidth="120" />
                                        <el-table-column prop="textField_lzc1wjoe" label="游戏账号" minWidth="120" />
                                        <el-table-column prop="dateField_ly8186y3" label="回收日期" minWidth="120">
                                            <template #default="scope">
                                                <p>{{ scope.row.dateField_ly8186y3 ?
        timestampToDate(scope.row.dateField_ly8186y3) : '' }}</p>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="numberField_ly8186y2" label="回收价" minWidth="120" />
                                        <el-table-column prop="numberField_lz2dkua6" label="包赔费" minWidth="120" />
                                        <el-table-column prop="numberField_ly8186xw" label="金额" minWidth="120" />
                                        <el-table-column prop="numberField_lyjp5xy4" label="利润差" minWidth="120" />
                                        <el-table-column prop="numberField_leog6999" label="收卖间隔" minWidth="120" />
                                        <el-table-column prop="numberField_ldwnnw0i" label="买家手机号" minWidth="120" />
                                        <el-table-column prop="textField_lyiegp57" label="备注" minWidth="120" />
                                    </el-table>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="销售总额：">
                                    <p>{{ state.relevanceInfo.numberField_lycge4ck || 0.00 }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="实销金额：">
                                    <p>{{ state.relevanceInfo.numberField_lyjqwoen || 0.00 }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                </el-collapse>
            </el-form>

            <!-- 运营订单 -->
            <el-form ref="relevanceRef" :model="state.relevanceInfo" v-else-if="state.flowMenu === '运营订单'">
                <el-collapse v-model="yidaActiveNames" @change="handleyidaChange" class="yida-collapse">
                    <el-collapse-item title="状态信息分组" name="1">
                        <el-row>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="收款核销状态：">
                                    <p>{{ state.relevanceInfo.radioField_lyo4e3uc }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="付款核销状态：">
                                    <p>{{ state.relevanceInfo.radioField_m0dgsyjr }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="收款状态：">
                                    <p>{{ state.relevanceInfo.radioField_lyo4e3u8 }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="付款状态：">
                                    <p>{{ state.relevanceInfo.radioField_m0dgsyjq }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="基本信息分组" name="2">
                        <el-row>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据类型：">
                                    <p>{{ state.relevanceInfo.radioField_lype0baz }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据日期：">
                                    <p>{{ state.relevanceInfo.dateField_lyicv3d3 ?
        timestampToDate(state.relevanceInfo.dateField_lyicv3d3) : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据编号：">
                                    <p>{{ state.relevanceInfo.textField_ly73ykjc }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="供应商：">
                                    <p>{{ state.relevanceInfo.associationFormField_ly5ifarv_id ?
        tranString(state.relevanceInfo.associationFormField_ly5ifarv_id) : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="业务员：">
                                    <p>{{ state.relevanceInfo.employeeField_lype0bbd ?
        state.relevanceInfo.employeeField_lype0bbd[0] : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="备注：">
                                    <p class="yidaDialogRemark">{{ state.relevanceInfo.textField_lype0bbe }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24" :xs="{ span: 24 }">
                                <el-form-item label="">
                                    <el-table :data="state.relevanceInfo.tableField_lyz7zldp" border
                                        style="width: 100%">
                                        <el-table-column prop="radioField_lyz7zlds" label="是否匹配" minWidth="120" />
                                        <el-table-column prop="dateField_lyz7zldu" label="订单创建时间" minWidth="120">
                                            <template #default="scope">
                                                <p>{{ scope.row.dateField_lyz7zldu ?
        timestampToDate(scope.row.dateField_lyz7zldu) : '' }}</p>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="selectField_lyz7zldw" label="交易状态" minWidth="120" />
                                        <el-table-column prop="departmentSelectField_lzxh80xm" label="所在部门"
                                            minWidth="120" />
                                        <el-table-column prop="selectField_lyz7zldy" label="淘宝店铺" minWidth="120" />
                                        <el-table-column prop="textField_lyz7zle0" label="订单编号" minWidth="120" />
                                        <el-table-column prop="numberField_lyz7zle2" label="订单金额" minWidth="120" />
                                    </el-table>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="运营总额：">
                                    <p>{{ state.relevanceInfo.numberField_lea3z5hp }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="收款实销金额：">
                                    <p>{{ state.relevanceInfo.numberField_lduf8xqe }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="付款实销金额：">
                                    <p>{{ state.relevanceInfo.numberField_m1vyd22y }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                </el-collapse>
            </el-form>

            <!-- 收号放款 -->
            <el-form ref="relevanceRef" :model="state.relevanceInfo" v-else-if="state.flowMenu === '收号放款'">
                <el-collapse v-model="yidaActiveNames" @change="handleyidaChange" class="yida-collapse">
                    <el-collapse-item title="状态信息分组" name="1">
                        <el-row>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="核销状态：">
                                    <p>{{ state.relevanceInfo.radioField_lyo4e3uc }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="付款状态：">
                                    <p>{{ state.relevanceInfo.radioField_lyo4e3u8 }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="基本信息分组" name="2">
                        <el-row>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据类型：">
                                    <p>{{ state.relevanceInfo.radioField_ly58t1vq }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据日期：">
                                    <p>{{ state.relevanceInfo.dateField_lye9pp7n ?
        timestampToDate(state.relevanceInfo.dateField_lye9pp7n) : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据编号：">
                                    <p>{{ state.relevanceInfo.textField_ly73ykjc }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="供应商：">
                                    <p>{{ state.relevanceInfo.associationFormField_ly5ifarv_id ?
        tranString(state.relevanceInfo.associationFormField_ly5ifarv_id) : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="业务员：">
                                    <p>{{ state.relevanceInfo.employeeField_lwx46ahu ?
        state.relevanceInfo.employeeField_lwx46ahu[0] : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="备注：">
                                    <p class="yidaDialogRemark">{{ state.relevanceInfo.textareaField_lzgc4emi }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24" :xs="{ span: 24 }">
                                <el-form-item label="">
                                    <el-table :data="state.relevanceInfo.tableField_lwx46ai5" border
                                        style="width: 100%">
                                        <el-table-column prop="departmentSelectField_ly6y8asw" label="所在部门"
                                            minWidth="120" />
                                        <el-table-column prop="selectField_ltdygwvr" label="游戏名称" minWidth="120" />
                                        <el-table-column prop="textField_ldwnnw04" label="游戏编号" minWidth="120" />
                                        <el-table-column prop="textField_lyy86lz4" label="游戏账号" minWidth="120" />
                                        <el-table-column prop="numberField_ldwnnw0a" label="回收价" minWidth="120" />
                                        <el-table-column prop="numberField_lzqqum6y" label="包赔费" minWidth="120" />
                                        <el-table-column prop="employeeField_lyy86lz5" label="回收人" minWidth="120" />
                                        <el-table-column prop="textareaField_lzgc4emj" label="备注" minWidth="120" />
                                    </el-table>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="付款总额：">
                                    <p>{{ state.relevanceInfo.numberField_ly5kfyiu }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="实销金额：">
                                    <p>{{ state.relevanceInfo.numberField_lyjz2dte }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="收款信息分组" name="3">
                        <el-row>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="账户类型：">
                                    <p>{{ state.relevanceInfo.selectField_lyphgqcl }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="收款户名：">
                                    <p>{{ state.relevanceInfo.textField_lyphgqcm }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="收款账号：">
                                    <p>{{ state.relevanceInfo.textField_lyphgqck }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="开户银行：">
                                    <p>{{ state.relevanceInfo.textField_lztx0yde }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="一键付款分组" name="4">
                        <el-row>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="付款账号：">
                                    <p>{{ state.relevanceInfo.associationFormField_lyo4e3tx_id ?
        tranString(state.relevanceInfo.associationFormField_lyo4e3tx_id) : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="付款户名：">
                                    <p>{{ state.relevanceInfo.textField_lyy86lz6 }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="付款金额：">
                                    <p>{{ state.relevanceInfo.numberField_lyo4e3tz }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                </el-collapse>
            </el-form>

            <!-- 账号问题 -->
            <el-form ref="relevanceRef" :model="state.relevanceInfo" v-else-if="state.flowMenu === '账号问题'">
                <el-collapse v-model="yidaActiveNames" @change="handleyidaChange" class="yida-collapse">
                    <el-collapse-item title="状态信息分组" name="1">
                        <el-row>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="收款核销状态：">
                                    <p>{{ state.relevanceInfo.radioField_m0jdu6sx }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="付款核销状态：">
                                    <p>{{ state.relevanceInfo.radioField_m0nfpxzy }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="收款状态：">
                                    <p>{{ state.relevanceInfo.radioField_m0jdu6sy }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="付款状态：">
                                    <p>{{ state.relevanceInfo.radioField_m0nfpxzz }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="基本信息分组" name="2">
                        <el-row>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据日期：">
                                    <p>{{ state.relevanceInfo.dateField_lypfij38 ?
        timestampToDate(state.relevanceInfo.dateField_lypfij38) : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据编号：">
                                    <p>{{ state.relevanceInfo.textField_lypfij39 }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="问题类型：">
                                    <p>{{ state.relevanceInfo.radioField_lzzj4t0u }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="回收日期：">
                                    <p>{{ state.relevanceInfo.dateField_ldxahn2z }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="所在部门：">
                                    <p>{{ state.relevanceInfo.departmentSelectField_llm43fsm }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="游戏名称：">
                                    <p>{{ state.relevanceInfo.selectField_ldwopc1d }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="游戏编号：">
                                    <p>{{ state.relevanceInfo.textField_ldwnnw04 }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="游戏账号：">
                                    <p>{{ state.relevanceInfo.textField_lzzj4t0v }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="回收价：">
                                    <p>{{ state.relevanceInfo.numberField_ldwnnw0a }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="号库状态：">
                                    <p>{{ state.relevanceInfo.radioField_lyo62228 }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="放款状态：">
                                    <p>{{ state.relevanceInfo.radioField_lyo6222b }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="备注：">
                                    <p class="yidaDialogRemark">{{ state.relevanceInfo.textareaField_lzzj4t0y }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="售后结果分组" name="3">
                        <el-row>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="售后结果（原号主或包赔平台）：">
                                    <p>{{ state.relevanceInfo.radioField_lzzj4t10 }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="要回金额：">
                                    <p>{{ state.relevanceInfo.numberField_ldx05jzx }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="备注：">
                                    <p class="yidaDialogRemark">{{ state.relevanceInfo.textField_lzzj4t1c }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="售后结果（客户）：">
                                    <p>{{ state.relevanceInfo.radioField_lzzj4t11 }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="补偿金额：">
                                    <p>{{ state.relevanceInfo.numberField_le9q8ojw }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="备注：">
                                    <p class="yidaDialogRemark">{{ state.relevanceInfo.textField_lzzj4t1d }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="核实信息分组" name="4">
                        <el-row>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="核实意见：">
                                    <p>{{ state.relevanceInfo.textareaField_m012khpg }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                </el-collapse>
            </el-form>

            <!-- 收款单 -->
            <el-form ref="relevanceRef" :model="state.relevanceInfo" v-else-if="state.flowMenu === '收款单'">
                <el-collapse v-model="yidaActiveNames" @change="handleyidaChange" class="yida-collapse">
                    <el-collapse-item title="状态信息分组" name="1">
                        <el-row>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="核销状态：">
                                    <p>{{ state.relevanceInfo.radioField_lz2xr881 }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="收款状态：">
                                    <p>{{ state.relevanceInfo.radioField_lzgsnzke }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="基本信息分组" name="2">
                        <el-row>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据类型：">
                                    <p>{{ state.relevanceInfo.radioField_ly58t1vq }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据日期：">
                                    <p>{{ state.relevanceInfo.dateField_lyicv3d3 ?
        timestampToDate(state.relevanceInfo.dateField_lyicv3d3) : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据编号：">
                                    <p>{{ state.relevanceInfo.textField_ly73ykjc }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="往来单位：">
                                    <p>{{ state.relevanceInfo.associationFormField_ly58t1w8_id ?
        tranString(state.relevanceInfo.associationFormField_ly58t1w8_id) : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="业务类型：">
                                    <p>{{ state.relevanceInfo.selectField_ly58t1w9 }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="业务员：">
                                    <p>{{ state.relevanceInfo.employeeField_lyi1k7ao ?
        state.relevanceInfo.employeeField_lyi1k7ao[0] : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="备注：">
                                    <p class="yidaDialogRemark">{{ state.relevanceInfo.textField_lyo3v6tk }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24" :xs="{ span: 24 }">
                                <el-form-item label="">
                                    <el-table :data="state.relevanceInfo.tableField_llnetcvd" border
                                        style="width: 100%">
                                        <el-table-column prop="associationFormField_m0dwlkdg" label="对账单"
                                            minWidth="120" />
                                        <el-table-column prop="dateField_lycsht5h" label="入账时间" minWidth="120">
                                            <template #default="scope">
                                                <p>{{ scope.row.dateField_lycsht5h ?
        timestampToDate(scope.row.dateField_lycsht5h) : '' }}</p>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="selectField_m0g0utzk" label="收款账号" minWidth="120" />
                                        <el-table-column prop="textField_m0g0utzm" label="收款户名" minWidth="120" />
                                        <el-table-column prop="textField_lycsht5n" label="对方名称" minWidth="120" />
                                        <el-table-column prop="textField_lycsht5m" label="对方账号" minWidth="120" />
                                        <el-table-column prop="textField_lycsht5j" label="订单号" minWidth="120" />
                                        <el-table-column prop="textField_m0g20ghd" label="支付宝交易号" minWidth="120" />
                                        <el-table-column prop="numberField_lycsht5k" label="收款金额" minWidth="120" />
                                        <el-table-column prop="textareaField_m0g0utzq" label="备注" minWidth="120" />
                                        <el-table-column prop="textField_m0tdup4q" label="流水ID" minWidth="120" />
                                    </el-table>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="收款合计：">
                                    <p>{{ state.relevanceInfo.numberField_ly58t1wl }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="现金折扣：">
                                    <p>{{ state.relevanceInfo.numberField_lyr1blyt }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="可核销金额：">
                                    <p>{{ state.relevanceInfo.numberField_ly58t1y1 }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="核销明细分组" name="3">
                        <el-row>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="未核销金额：">
                                    <p>{{ state.relevanceInfo.numberField_ly58t1y0 }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="本次核销金额：">
                                    <p>{{ state.relevanceInfo.numberField_ly58t1y1 }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="核销差额：">
                                    <p>{{ state.relevanceInfo.numberField_ly58t1y2 }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据范围：">
                                    <p>{{ state.relevanceInfo.multiSelectField_ly58t1xh ?
        toStrings(state.relevanceInfo.multiSelectField_ly58t1xh) : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24" :xs="{ span: 24 }">
                                <el-form-item label="核销明细表">
                                    <el-table :data="state.relevanceInfo.tableField_lz9lk4bj" border
                                        style="width: 100%">
                                        <el-table-column prop="selectField_lz9lk4br" label="单据类型" minWidth="120" />
                                        <el-table-column prop="dateField_lz9lk4bp" label="单据日期" minWidth="120">
                                            <template #default="scope">
                                                <p>{{ scope.row.dateField_lz9lk4bp ?
        timestampToDate(scope.row.dateField_lz9lk4bp) : '' }}</p>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="textField_lz9lk4bq" label="单据编号" minWidth="120" />
                                        <el-table-column prop="departmentSelectField_lz9lk4bo" label="所在部门"
                                            minWidth="120" />
                                        <el-table-column prop="textField_lzc5qzi7" label="收入名称" minWidth="120" />
                                        <el-table-column prop="textField_lz9lk4c4" label="游戏名称" minWidth="120" />
                                        <el-table-column prop="textField_lz9lk4c3" label="游戏编号" minWidth="120" />
                                        <el-table-column prop="textField_lzc5qzi5" label="游戏账号" minWidth="120" />
                                        <el-table-column prop="textField_lzdhjjrl" label="订单编号" minWidth="120" />
                                        <el-table-column prop="radioField_m0gewfyw" label="收支类型" minWidth="120" />
                                        <el-table-column prop="numberField_lz9lk4c1" label="金额" minWidth="120" />
                                        <el-table-column prop="numberField_lz9lk4c0" label="可核销金额" minWidth="120" />
                                        <el-table-column prop="numberField_lz9lk4bz" label="本次核销金额" minWidth="120" />
                                        <el-table-column prop="numberField_lz9lk4by" label="折扣分摊额" minWidth="120" />
                                    </el-table>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="是否自动核销：">
                                    <p>{{ state.relevanceInfo.selectField_lymakgcw }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                </el-collapse>
            </el-form>

            <!-- 付款单 -->
            <el-form ref="relevanceRef" :model="state.relevanceInfo" v-else-if="state.flowMenu === '付款单'">
                <el-collapse v-model="yidaActiveNames" @change="handleyidaChange" class="yida-collapse">
                    <el-collapse-item title="状态信息分组" name="1">
                        <el-row>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="核销状态：">
                                    <p>{{ state.relevanceInfo.radioField_lz2xr881 }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="付款状态：">
                                    <p>{{ state.relevanceInfo.radioField_lzgssg0d }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="基本信息分组" name="2">
                        <el-row>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据类型：">
                                    <p>{{ state.relevanceInfo.radioField_ly58t1vq }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据日期：">
                                    <p>{{ state.relevanceInfo.dateField_lyicv3d3 ?
        timestampToDate(state.relevanceInfo.dateField_lyicv3d3) : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据编号：">
                                    <p>{{ state.relevanceInfo.textField_ly73ykjc }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="往来单位：">
                                    <p>{{ state.relevanceInfo.associationFormField_ly58t1w8_id ?
        tranString(state.relevanceInfo.associationFormField_ly58t1w8_id) : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="业务类型：">
                                    <p>{{ state.relevanceInfo.selectField_ly58t1w9 }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="业务员：">
                                    <p>{{ state.relevanceInfo.employeeField_lyi1k7ao ?
        state.relevanceInfo.employeeField_lyi1k7ao[0] : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="备注：">
                                    <p class="yidaDialogRemark">{{ state.relevanceInfo.textField_lyo46d6k }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }" v-if="state.relevanceInfo.radioField_m0w0uvzz">
                                <el-form-item label="选择对账单：">
                                    <p>{{ state.relevanceInfo.radioField_m0w0uvzz }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24" :xs="{ span: 24 }"
                                v-if="state.relevanceInfo.radioField_m0w0uvzz && state.relevanceInfo.radioField_m0w0uvzz == '是'">
                                <el-form-item label="">
                                    <el-table :data="state.relevanceInfo.tableField_llnetcvd" border
                                        style="width: 100%">
                                        <el-table-column prop="associationFormField_lysksosx" label="对账单"
                                            minWidth="120" />
                                        <el-table-column prop="dateField_lycsht5h" label="入账时间" minWidth="120">
                                            <template #default="scope">
                                                <p>{{ scope.row.dateField_lycsht5h ?
        timestampToDate(scope.row.dateField_lycsht5h) : '' }}</p>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="selectField_m0f1ecbj" label="付款账号" minWidth="120" />
                                        <el-table-column prop="textField_m0f1ecbl" label="付款户名" minWidth="120" />
                                        <el-table-column prop="textField_lycsht5n" label="对方名称" minWidth="120" />
                                        <el-table-column prop="textField_lycsht5m" label="对方账号" minWidth="120" />
                                        <el-table-column prop="textField_lycsht5j" label="订单号" minWidth="120" />
                                        <el-table-column prop="textField_m0g23w3q" label="支付宝交易号" minWidth="120" />
                                        <el-table-column prop="numberField_lycsht5k" label="付款金额" minWidth="120" />
                                        <el-table-column prop="textareaField_lycsht5o" label="备注" minWidth="120" />
                                        <el-table-column prop="textField_m0te1jza" label="流水ID" minWidth="120" />
                                    </el-table>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24" :xs="{ span: 24 }"
                                v-if="state.relevanceInfo.radioField_m0w0uvzz && state.relevanceInfo.radioField_m0w0uvzz == '否'">
                                <el-form-item label="">
                                    <el-table :data="state.relevanceInfo.tableField_m0w0uw0c" border
                                        style="width: 100%">
                                        <el-table-column prop="dateField_m0w0uw01" label="入账时间" minWidth="120">
                                            <template #default="scope">
                                                <p>{{ scope.row.dateField_m0w0uw01 ?
        timestampToDate(scope.row.dateField_m0w0uw01) : '' }}</p>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="selectField_m0w0uw02" label="付款账号" minWidth="120" />
                                        <el-table-column prop="textField_m0w0uw03" label="付款户名" minWidth="120" />
                                        <el-table-column prop="textField_m0w0uw04" label="对方名称" minWidth="120" />
                                        <el-table-column prop="textField_m0w0uw05" label="对方账号" minWidth="120" />
                                        <el-table-column prop="textField_m0w0uw06" label="订单号" minWidth="120" />
                                        <el-table-column prop="textField_m0w0uw07" label="支付宝交易号" minWidth="120" />
                                        <el-table-column prop="numberField_m0w0uw08" label="付款金额" minWidth="120" />
                                        <el-table-column prop="textareaField_m0w0uw09" label="备注" minWidth="120" />
                                    </el-table>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="付款合计：">
                                    <p>{{ state.relevanceInfo.numberField_ly58t1wl }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="现金折扣：">
                                    <p>{{ state.relevanceInfo.numberField_lyr1blyt }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="可核销金额：">
                                    <p>{{ state.relevanceInfo.numberField_ly58t1y1 }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="核销明细分组" name="3">
                        <el-row>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="未核销金额：">
                                    <p>{{ state.relevanceInfo.numberField_ly58t1y0 }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="本次核销金额：">
                                    <p>{{ state.relevanceInfo.numberField_ly58t1y1 }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="核销差额：">
                                    <p>{{ state.relevanceInfo.numberField_ly58t1y2 }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据范围：">
                                    <p>{{ state.relevanceInfo.multiSelectField_ly58t1xh ?
        toStrings(state.relevanceInfo.multiSelectField_ly58t1xh) : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24" :xs="{ span: 24 }">
                                <el-form-item label="">
                                    <el-table :data="state.relevanceInfo.tableField_lz8a2w5i" border
                                        style="width: 100%">
                                        <el-table-column prop="selectField_lz8a2w5k" label="单据类型" minWidth="120" />
                                        <el-table-column prop="dateField_lz8a2w5o" label="单据日期" minWidth="120">
                                            <template #default="scope">
                                                <p>{{ scope.row.dateField_lz8a2w5o ?
        timestampToDate(scope.row.dateField_lz8a2w5o) : '' }}</p>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="textField_lz8a2w5m" label="单据编号" minWidth="120" />
                                        <el-table-column prop="departmentSelectField_lz8a2w5q" label="所在部门"
                                            minWidth="120" />
                                        <el-table-column prop="selectField_lz8a2w5s" label="游戏名称" minWidth="120" />
                                        <el-table-column prop="textField_lz8a2w5u" label="游戏编号" minWidth="120" />
                                        <el-table-column prop="textField_lzc60ft1" label="游戏账号" minWidth="120" />
                                        <el-table-column prop="textField_lzc60ft3" label="费用名称" minWidth="120" />
                                        <el-table-column prop="associationFormField_ldxhle1k" label="手机号码"
                                            minWidth="120" />
                                        <el-table-column prop="textField_m0f5p48q" label="订单编号" minWidth="120" />
                                        <el-table-column prop="radioField_m0gervf3" label="收支类型" minWidth="120" />
                                        <el-table-column prop="numberField_lz8a2w5w" label="金额" minWidth="120" />
                                        <el-table-column prop="numberField_lz8a2w5x" label="可核销金额" minWidth="120" />
                                        <el-table-column prop="numberField_lz8a2w5y" label="本次核销金额" minWidth="120" />
                                        <el-table-column prop="numberField_lz8a2w5z" label="折扣分摊额" minWidth="120" />
                                    </el-table>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="是否确认核销：">
                                    <p>{{ state.relevanceInfo.radioField_y56tcfqh }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="是否自动核销：">
                                    <p>{{ state.relevanceInfo.selectField_lymakgcw }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                </el-collapse>
            </el-form>

            <!-- 其他收入 -->
            <el-form ref="relevanceRef" :model="state.relevanceInfo" v-else-if="state.flowMenu === '其他收入'">
                <el-collapse v-model="yidaActiveNames" @change="handleyidaChange" class="yida-collapse">
                    <el-collapse-item title="状态信息分组" name="1">
                        <el-row>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="核销状态：">
                                    <p>{{ state.relevanceInfo.radioField_lyo4e3uc }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="收款状态：">
                                    <p>{{ state.relevanceInfo.radioField_lyo4e3u8 }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="基本信息分组" name="2">
                        <el-row>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据类型：">
                                    <p>{{ state.relevanceInfo.radioField_lyo2e1ui }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据日期：">
                                    <p>{{ state.relevanceInfo.dateField_lyiegp55 ?
        timestampToDate(state.relevanceInfo.dateField_lyiegp55) : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据编号：">
                                    <p>{{ state.relevanceInfo.textField_ly73ykjc }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="业务类型：">
                                    <p>{{ state.relevanceInfo.selectField_m0w85n5z }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="往来单位：">
                                    <p>{{ state.relevanceInfo.associationFormField_ly5ifarv_id ?
        tranString(state.relevanceInfo.associationFormField_ly5ifarv_id) : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="业务员：">
                                    <p>{{ state.relevanceInfo.employeeField_lz7rz2v0 ?
        state.relevanceInfo.employeeField_lz7rz2v0[0] : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="备注：">
                                    <p class="yidaDialogRemark">{{ state.relevanceInfo.textField_lyo2e1us }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24" :xs="{ span: 24 }">
                                <el-form-item label="">
                                    <el-table :data="state.relevanceInfo.tableField_ly6n4ibl" border
                                        style="width: 100%">
                                        <el-table-column prop="departmentSelectField_llm3pdr7" label="所在部门"
                                            minWidth="120" />
                                        <el-table-column prop="textField_lyo8sh9y" label="收入名称" minWidth="120" />
                                        <el-table-column prop="numberField_ly6n4ibo" label="金额" minWidth="120" />
                                        <el-table-column prop="textField_lymnh7fa" label="备注" minWidth="120" />
                                        <el-table-column prop="numberField_lzgtaap6" label="已核销金额" minWidth="120" />
                                    </el-table>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="收入总额：">
                                    <p>{{ state.relevanceInfo.numberField_ly5kfyiu }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="实销金额：">
                                    <p>{{ state.relevanceInfo.numberField_lys09x3t }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="一键收款分组" name="3">
                        <el-row>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="收款账号：">
                                    <p>{{ state.relevanceInfo.associationFormField_lyo2e1ux_id ?
        tranString(state.relevanceInfo.associationFormField_lyo2e1ux_id) : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="收款户名：">
                                    <p>{{ state.relevanceInfo.textField_lyy8retv }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="收款金额：">
                                    <p>{{ state.relevanceInfo.numberField_lyo2e1v4 }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="支付宝交易号：">
                                    <p>{{ state.relevanceInfo.textField_m1aqp2sl }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                </el-collapse>
            </el-form>

            <!-- 费用支出 -->
            <el-form ref="relevanceRef" :model="state.relevanceInfo" v-else-if="state.flowMenu === '费用支出'">
                <el-collapse v-model="yidaActiveNames" @change="handleyidaChange" class="yida-collapse">
                    <el-collapse-item title="状态信息分组" name="1">
                        <el-row>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="核销状态：">
                                    <p>{{ state.relevanceInfo.radioField_lyo4e3uc }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="付款状态：">
                                    <p>{{ state.relevanceInfo.radioField_lyo4e3u8 }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="基本信息分组" name="2">
                        <el-row>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据类型：">
                                    <p>{{ state.relevanceInfo.radioField_lyo2e1ui }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据日期：">
                                    <p>{{ state.relevanceInfo.dateField_lyiegp55 ?
        timestampToDate(state.relevanceInfo.dateField_lyiegp55) : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="单据编号：">
                                    <p>{{ state.relevanceInfo.textField_ly73ykjc }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="支出类型：">
                                    <p>{{ state.relevanceInfo.radioField_lyo350nc }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="业务类型：">
                                    <p>{{ state.relevanceInfo.selectField_m0w7ze16 }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="往来单位：">
                                    <p>{{ state.relevanceInfo.associationFormField_lyo350nl_id ?
                                        tranString(state.relevanceInfo.associationFormField_lyo350nl_id) : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="业务员：">
                                    <p>{{ state.relevanceInfo.employeeField_lz80glbk ?
                                        state.relevanceInfo.employeeField_lz80glbk[0] : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="备注：">
                                    <p class="yidaDialogRemark">{{ state.relevanceInfo.textField_lzy4kqq8 }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24" :xs="{ span: 24 }">
                                <el-form-item label="">
                                    <el-table :data="state.relevanceInfo.tableField_ly6n4ibl" border
                                        style="width: 100%">
                                        <el-table-column prop="departmentSelectField_llm3pdr7" label="所在部门"
                                            minWidth="120" />
                                        <el-table-column prop="textField_lyo8sh9y" label="费用名称" minWidth="120" />
                                        <el-table-column prop="associationFormField_ldxhle1k" label="手机号码"
                                            minWidth="120" />
                                        <el-table-column prop="associationFormField_lfanejmi" label="游戏号库"
                                            minWidth="120" />
                                        <el-table-column prop="selectField_lyo350nm" label="游戏名称" minWidth="120" />
                                        <el-table-column prop="textField_lzc24a4n" label="游戏编号" minWidth="120" />
                                        <el-table-column prop="textField_lzc24a4p" label="游戏账号" minWidth="120" />
                                        <el-table-column prop="numberField_ly6n4ibo" label="金额" minWidth="120" />
                                        <el-table-column prop="numberField_lz80glbj" label="费用分摊" minWidth="120" />
                                        <el-table-column prop="numberField_lzgolvp1" label="已核销金额" minWidth="120" />
                                        <el-table-column prop="textareaField_lzgs4pfv" label="备注" minWidth="120" />
                                    </el-table>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="付款总额：">
                                    <p>{{ state.relevanceInfo.numberField_ldwudpgc }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="实销金额：">
                                    <p>{{ state.relevanceInfo.numberField_lys09x3t }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="一键付款分组" name="3">
                        <el-row>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="付款账号：">
                                    <p>{{ state.relevanceInfo.associationFormField_lyo2e1ux_id ?
                                        tranString(state.relevanceInfo.associationFormField_lyo2e1ux_id) : '' }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="付款户名：">
                                    <p>{{ state.relevanceInfo.textField_lyy8qcly }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="付款金额：">
                                    <p>{{ state.relevanceInfo.numberField_lyo2e1v4 }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="支付宝交易号：">
                                    <p>{{ state.relevanceInfo.textField_m1arm2z0 }}</p>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="{ span: 24 }">
                                <el-form-item label="自动审核：">
                                    <p>{{ state.relevanceInfo.radioField_lzwggl5w }}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                </el-collapse>
            </el-form>

        </el-scrollbar>
    </el-dialog>
</template>

<script setup lang="ts">
import { reactive, ref, watch, inject, onMounted, } from 'vue'
import { flowInfo } from '../../api/index';

interface Props {
    dialogFormVisible?: boolean,//弹窗显隐
    flowMenu?: string,//流程名称
    formUuid?: string,//表单ID
    instanceId?: string,//实例ID
}
const props = withDefaults(defineProps<Props>(), {
    dialogFormVisible: false,
    flowMenu: '',
    formUuid: '',
    instanceId: '',
})

const state: {
    dialogFormVisible: boolean,//弹窗显隐
    flowMenu: string,//流程名称
    formUuid: string,//表单ID
    instanceId: string,//实例ID
    relevanceInfo: any,
} = reactive({
    dialogFormVisible: false,//弹窗显隐
    flowMenu: "",//流程名称
    formUuid: "",//表单ID
    instanceId: "",//实例ID
    relevanceInfo: {},
})

const yidaActiveNames = ref(['1', '2', '3', '4', '5', '6', '7', '8'])
const handleyidaChange = (val: string[]) => { }

// 流程详情
const getFlowInfo = async () => {
    state.relevanceInfo = {};
    let jsons = {
        formId: state.formUuid,
        instanceId: state.instanceId,
    };
    await flowInfo(jsons)
        .then((res: any) => {
            // console.log('获取详情-成功', res)//成功
            state.relevanceInfo = res.result.data;
        })
        .catch((error: any) => {
            // console.log('获取详情-失败', error)//失败
            state.relevanceInfo = {};
        })
};

// 转换时间戳
const timestampToDate = (timestamp: string) => {
    let date = new Date(timestamp);
    let year = date.getFullYear();
    let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1; // 月份从0开始，需要加1
    let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    return `${year}-${month}-${day}`;
}

// 转字符串
const toStrings = (val: any) => {
    return val.join(',')
}

// 转字符串
const tranString = (val: any) => {
    return JSON.parse(JSON.parse(val))[0].title
}

const emits = defineEmits(['toggleDialog']);
const toggledialogVisible = () => {
    emits('toggleDialog', false)
    state.dialogFormVisible = false;
    state.flowMenu = '';
    state.formUuid = '';
    state.instanceId = '';
}



// 转换时间戳
const timestampToDate1 = (timestamp: string) => {
    var date = new Date(timestamp); // 时间戳转换成Date对象
    var year = date.getFullYear(); // 获取年份
    var month = date.getMonth() + 1; // 获取月份，需要+1因为月份从0开始
    var day = date.getDate(); // 获取日
    var hours = date.getHours(); // 获取小时
    var minutes = date.getMinutes(); // 获取分钟
    var seconds = date.getSeconds(); // 获取秒钟
 
    // 格式化月份、日期、小时、分钟和秒
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
 
    // 组合成日期时间字符串
    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;

}


watch(
    () => props.dialogFormVisible,
    (newVal) => {
        if (newVal) {
            state.dialogFormVisible = props.dialogFormVisible;
            state.flowMenu = props.flowMenu;
            state.formUuid = props.formUuid;
            state.instanceId = props.instanceId;
            getFlowInfo();
        }





        
console.log(timestampToDate1(1730390400000),'哈哈哈哈2')
console.log(timestampToDate1(1732982399999),'哈哈哈哈1')
    }
)
</script>

<style lang="less">
.yidaDialog {
    .el-dialog__header {
        margin-left: 16px;
        padding-left: 0;
        font-size: 15px;
        text-align: left;
        border-bottom: 1px solid var(--ba-bg-color);
    }

    .el-dialog__body {
        padding-top: 15px;
    }

    .el-dialog__headerbtn:hover .el-dialog__close {
        color: #2e4099;
    }
}

.yida-collapse {
    border-bottom: none;

    .el-form-item__content p {
        padding: 0;
        margin: 0;
    }

    .el-collapse-item {
        margin-bottom: 10px;
        border-left: 1px solid #e8ebee;
        border-right: 1px solid #e8ebee;
    }

    .el-collapse-item__header {
        font-size: 15px;
        color: #111;
        background-color: #f1f2f3;
        position: relative;
        padding-left: 34px;

        .el-collapse-item__arrow {
            position: absolute;
            left: 10px;
            font-size: 18px;
        }
    }

    .el-collapse-item__content {
        padding: 15px 30px 0;

        .el-form-item {

            .el-form-item__label {
                width: 130px;
                color: #111;
                text-align: right;
            }
        }
    }
}

.yidaDialogRemark {
    word-break: break-all;
    white-space: pre-warp;
    text-align: left;
}
</style>
